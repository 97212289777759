import Cookies from 'js-cookie';
import { CUTCOPY_EXPIRATION, MV_COOKIE, MV_COOKIE_DATA } from '../config';
import { editContent, editFolder } from '../store/actions';
import { CONTENT_TYPES } from '../config/constants';

export const handlerMoving = (uuids, data, setMoving) => {
  Cookies.remove(MV_COOKIE);
  Cookies.set(MV_COOKIE, JSON.stringify(uuids), {
    expires: new Date(new Date().getTime() + CUTCOPY_EXPIRATION * 1000),
  });
  Cookies.remove(MV_COOKIE_DATA);
  Cookies.set(MV_COOKIE_DATA, JSON.stringify(data), {
    expires: new Date(new Date().getTime() + CUTCOPY_EXPIRATION * 1000),
  });
  setMoving(uuids);
};

export const handleMove = (props) => {
  const { loginToken, folderid, dispatch, setMoving } = props;
  const moving = JSON.parse(Cookies.get(MV_COOKIE));
  const moving_data = JSON.parse(Cookies.get(MV_COOKIE_DATA));
  const destination = folderid ? folderid : '';
  moving.forEach((m, key) => {
    if (moving_data[key].type === CONTENT_TYPES.FOLDER) {
      dispatch(
        editFolder(loginToken, m, {
          parent: destination ? destination : null,
        }),
      );
    } else {
      dispatch(
        editContent(loginToken, m, {
          parent: destination ? destination : null,
        }),
      );
    }
  });
  setMoving('');
  Cookies.remove(MV_COOKIE);
  Cookies.remove(MV_COOKIE_DATA);
};
