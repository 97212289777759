import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import {
  AppstoreOutlined,
  BarsOutlined,
  WarningOutlined,
  UserOutlined,
} from '@ant-design/icons';

import {
  ContentTable,
  ContentCards,
  ActionsComponent,
  Breadcrumb,
  LoginComponent,
} from '../components';
import { ModalRename } from '../components/Modals';
import { CONTENT_TYPES } from '../config/constants';
import notifcationMessages from '../helpers/hocs/notificationMessages';
import { folderFilesSorter } from '../helpers/utils';
import {
  getContents,
  editFolder,
  editContent,
  deleteFolder,
  deleteContent,
} from '../store/actions';

import { Col, Modal, Result, Row, Segmented, Space, theme } from 'antd';
import Cookies from 'js-cookie';

const Home = ({ apiMessage, contextHolder }) => {
  const messages = defineMessages({
    content_cards: {
      id: 'content_cards',
      defaultMessage: 'Cards',
    },
    content_list: {
      id: 'content_list',
      defaultMessage: 'List',
    },
    login_page_message: {
      id: 'login_page_message',
      defaultMessage: 'Please go to the {login_link}',
    },
  });
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // @ts-ignore
  const loginToken = useSelector((state) => state.login.sessionData.token);
  // @ts-ignore
  const contentsState = useSelector((state) => state.contents);
  // @ts-ignore
  const createFolderState = useSelector((state) => state.createFolder);
  // @ts-ignore
  const deleteFolderState = useSelector((state) => state.deleteFolder);
  // @ts-ignore
  const deleteContentState = useSelector((state) => state.deleteContent);
  // @ts-ignore
  const editFolderState = useSelector((state) => state.editFolder);
  // @ts-ignore
  const editContentState = useSelector((state) => state.editContent);
  // @ts-ignore
  const uploadFileState = useSelector((state) => state.uploadFile);

  const { token: globalToken } = theme.useToken();
  const { folderid } = useParams();
  const initialRenameItem = {
    title: '',
    uuid: '',
    type: '',
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const [renameModal, setRenameModal] = useState(false);
  const [renameItem, setRenameItem] = useState(initialRenameItem);
  const [format, setFormat] = useState('cards');
  const [sortOrder, setSortOrder] = useState('ascend');
  // eslint-disable-next-line no-unused-vars
  const [sortField, setSortField] = useState('title');
  const [data, setData] = useState(
    contentsState.items.sort(folderFilesSorter(sortOrder, sortField)),
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [moving, setMoving] = useState(Cookies.get('__mv') ?? []);

  const deleteContext = {
    openDeleteModal,
    setOpenDeleteModal,
    deleteItems,
    setDeleteItems,
  };
  const renameContext = {
    renameModal,
    setRenameModal,
    renameItem,
    setRenameItem,
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    setData(contentsState.items.sort(folderFilesSorter(sortOrder, sortField)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder, contentsState.loaded]);

  useEffect(() => {
    // @ts-ignore
    !folderid && loginToken && dispatch(getContents(loginToken));
    // @ts-ignore
    folderid && loginToken && dispatch(getContents(loginToken, folderid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    folderid,
    loginToken,
    editFolderState.loaded,
    editContentState.loaded,
    createFolderState.loaded,
    deleteFolderState.loaded,
    deleteContentState.loaded,
    uploadFileState.loaded,
  ]);

  const makeRenameEffective = () => {
    if (renameItem.title && renameItem.uuid) {
      if (renameItem.type === CONTENT_TYPES.FOLDER) {
        dispatch(
          // @ts-ignore
          editFolder(loginToken, renameItem.uuid, {
            title: renameItem.title,
          }),
        );
      } else {
        dispatch(
          // @ts-ignore
          editContent(loginToken, renameItem.uuid, {
            title: renameItem.title,
          }),
        );
      }
      setRenameModal(false);
      rowSelection.onChange([]);
    }
  };

  return (
    <div>
      {contextHolder}
      <ModalRename
        makeRenameEffective={makeRenameEffective}
        renaming={renameContext}
        loading={editFolderState.loading}
      />
      <Modal
        title={
          <Space>
            <WarningOutlined
              style={{
                fontSize: globalToken.fontSizeLG,
                color: globalToken.colorWarning,
              }}
              rev=""
            />
            <FormattedMessage
              id="Delete element"
              defaultMessage="Delete element"
            />
          </Space>
        }
        centered
        open={openDeleteModal}
        onOk={() => {
          deleteItems.forEach((di) => {
            if (di.type === CONTENT_TYPES.FOLDER) {
              // @ts-ignore
              dispatch(deleteFolder(loginToken, di.uuid));
            } else {
              // @ts-ignore
              dispatch(deleteContent(loginToken, di.uuid));
            }
          });
          setSelectedRowKeys([]);
          setOpenDeleteModal(false);
        }}
        confirmLoading={deleteFolderState.loading || deleteContentState.loading}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
      >
        <FormattedMessage
          id="You are going to delete the following items:"
          defaultMessage="You are going to delete the following items:"
        />
        {deleteItems.length > 0 &&
          deleteItems.map((i) => {
            return i.title;
          })}
      </Modal>
      {loginToken && (
        <Row justify="center" align="middle">
          {/* <Col lg={18} sm={24}> */}
          <Col span={24}>
            {!hasSelected && !moving.length && <Breadcrumb />}
            {(hasSelected || moving.length > 0) && (
              <ActionsComponent
                rowSelection={rowSelection}
                moving={moving}
                setMoving={setMoving}
                apiMessage={apiMessage}
                renaming={renameContext}
                deleting={deleteContext}
              />
            )}
          </Col>
          {/* <Col lg={6} md={24}> */}
          <Col md={24}>
            <Segmented
              // @ts-ignore
              onChange={(v) => setFormat(v)}
              options={[
                {
                  value: 'cards',
                  label: intl.formatMessage(messages.content_cards),
                  icon: <AppstoreOutlined rev="" />,
                },
                {
                  value: 'list',
                  label: intl.formatMessage(messages.content_list),
                  icon: <BarsOutlined rev="" />,
                },
              ]}
            />
          </Col>
        </Row>
      )}
      {loginToken ? (
        <Row gutter={[16, 24]}>
          {format === 'list' && (
            <ContentTable
              dataSource={data}
              setSortOrder={setSortOrder}
              rowSelection={rowSelection}
              renaming={renameContext}
              setMoving={setMoving}
              moving={moving.length > 0}
              deleting={deleteContext}
            />
          )}
          {format === 'cards' && (
            <ContentCards
              dataSource={data}
              sortField={sortField}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              rowSelection={rowSelection}
              showContents={!moving.length}
              renaming={renameContext}
              setMoving={setMoving}
              deleting={deleteContext}
            />
          )}
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Result
              icon={
                <UserOutlined
                  rev=""
                  style={{ color: globalToken.colorPrimaryBg }}
                />
              }
              title={
                <FormattedMessage
                  id="You are not logged in."
                  defaultMessage="You are not logged in."
                />
              }
              extra={<LoginComponent navigate={navigate} />}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default notifcationMessages(Home);
