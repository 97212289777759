import axios from 'axios';
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_PATH}/api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const medias = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_PATH}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const baseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
const apiHeaders = (headers = {}) =>
  axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_PATH}/api`,
    headers: {
      ...baseHeaders,
      ...headers,
    },
  });

export default instance;
export { baseHeaders, apiHeaders, medias };
