import {
  FileImageOutlined,
  FileTextOutlined,
  VideoCameraOutlined,
  FileUnknownOutlined,
  AudioOutlined,
} from '@ant-design/icons';
export const DB_KEYS = {
  LANGUAGE: 'language',
  USER_DATA: 'user_data',
};

export const API_URLS = {
  LOGIN: '/login/',
  LOGOUT: '/logout/',
  RENEW_LOGIN: '/user/',
  FOLDERS: '/folders/',
  FILE: '/contents/',
  METADATA: '/contents/{{fileid}}/meta/',
  CONTENTS: '/contents/',
  REQUESTPASSWORDRESET: '/password/reset',
  PASSWORDRESET: '/password/reset/confirm',
  BREADCRUMB: '/breadcrumb',
  UPLOAD_FILE: '/contents/',
};

export const CONTENT_TYPES = {
  FOLDER: 'folder',
  VIDEO: 'video',
};

export const CUTCOPY_EXPIRATION = 60;
export const MV_COOKIE = '__mv';
export const MV_COOKIE_DATA = '__mv_titles';

export const FILE_TYPES = {
  'video/x-matroska': 1,
  'video/mp4': 1,
  'audio/wav': 2,
  'audio/mp3': 2,
  'image/jpeg': 3,
  'image/jpg': 3,
  'image/png': 3,
  'application/pdf': 4,
};

export const CONTENT_TYPE_ICONS = {
  irudia: (fontSize) => <FileImageOutlined style={{ fontSize }} />,
  video: (fontSize) => <VideoCameraOutlined style={{ fontSize }} />,
  document: (fontSize) => <FileTextOutlined style={{ fontSize }} />,
  audio: (fontSize) => <AudioOutlined style={{ fontSize }} />,
  else: (fontSize) => <FileUnknownOutlined style={{ fontSize }} />,
};

export const DATETIME_FORMAT = {
  hour: '2-digit',
  minute: '2-digit',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};
