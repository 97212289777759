/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
const getRequestKey = (actionType) => {
  return actionType.split('_')[0].toLowerCase();
};

const auth = (token) => {
  return { Authorization: `Token ${token}` };
};

export { getRequestKey, auth };
