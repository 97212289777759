import React from 'react';
import { Col, Row, Button, theme, Empty } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Card } from '../';
import { FormattedMessage } from 'react-intl';
import { CONTENT_TYPES } from '../../config/constants';
const ContentCards = ({
  dataSource,
  setSortOrder,
  sortField,
  sortOrder,
  rowSelection,
  showContents,
  renaming,
  setMoving,
  deleting,
}) => {
  const folders = dataSource.filter((ds) => ds.type === CONTENT_TYPES.FOLDER);
  const files = dataSource.filter((ds) => ds.type !== CONTENT_TYPES.FOLDER);
  const { token: globalToken } = theme.useToken();
  return (
    <>
      <Col span={24}>
        <Row>
          <Col xs={18} sm={20} lg={22} md={21}>
            <h2>
              <FormattedMessage id="Folders" defaultMessage="Folders" />
            </h2>
          </Col>
          <Col xs={6} sm={4} lg={2} md={3}>
            <Button
              // type="link"
              style={{ marginTop: globalToken.marginMD }}
              onClick={() =>
                setSortOrder(sortOrder === 'ascend' ? 'descend' : 'ascend')
              }
            >
              {sortField === 'title' && (
                <FormattedMessage id="Title" defaultMessage={'Title'} />
              )}{' '}
              {sortOrder === 'ascend' ? (
                <ArrowUpOutlined rev="" />
              ) : (
                <ArrowDownOutlined rev="" />
              )}
            </Button>
          </Col>
        </Row>
        <Row gutter={[20, 10]}>
          {folders.map((item, key) => (
            <Col key={key} xs={24} sm={12} md={10} lg={7} xxl={5}>
              <Card
                {...item}
                rowSelection={rowSelection}
                renaming={renaming}
                setMoving={setMoving}
                deleting={deleting}
              />
            </Col>
          ))}
          {folders.length === 0 && (
            <Col span={24}>
              <Empty
                description={
                  <FormattedMessage
                    id="There are no folders in this location..."
                    defaultMessage="There are no folders in this location..."
                  />
                }
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <h2>
            <FormattedMessage id="Files" defaultMessage="Files" />
          </h2>
        </Row>
        <Row gutter={[10, 10]}>
          {files.map((item, key) => (
            <Col key={key} xs={24} sm={12} lg={6} xxl={4}>
              <Card
                {...item}
                rowSelection={rowSelection}
                disabled={!showContents}
                renaming={renaming}
                setMoving={setMoving}
                deleting={deleting}
              />
            </Col>
          ))}
          {files.length === 0 && (
            <Col span={24}>
              <Empty
                description={
                  <FormattedMessage
                    id="There are no files in this location..."
                    defaultMessage="There are no files in this location..."
                  />
                }
              />
            </Col>
          )}
        </Row>
      </Col>
    </>
  );
};

export default ContentCards;
