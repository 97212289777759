import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  EditOutlined,
  DoubleRightOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { CONTENT_TYPES } from '../../config/constants';
import { clickSelectHandler } from '../../helpers/utils';
import FileCard from './FileCard';
import FolderCard from './FolderCard';
import { handlerMoving } from '../../helpers/contentActions';
import { Dropdown, Space } from 'antd';

const AnimamCard = (props) => {
  const navigate = useNavigate();
  const {
    uuid,
    type,
    rowSelection,
    disabled = false,
    renaming,
    setMoving,
    deleting,
  } = props;
  const { setRenameModal, setRenameItem } = renaming;
  const { setOpenDeleteModal, setDeleteItems } = deleting;

  const isSelected = rowSelection.selectedRowKeys.includes(uuid);

  const items = [
    {
      key: 'rename',
      label: (
        <span
          tabIndex={0}
          role="button"
          onClick={(ev) => {
            setRenameModal(true);
            setRenameItem({ ...props });
          }}
          onKeyDown={() => {
            setRenameModal(true);
            setRenameItem({ ...props });
          }}
        >
          <Space>
            <EditOutlined rev="" />
            <FormattedMessage id="Rename" defaultMessage="Rename" />
          </Space>
        </span>
      ),
    },
    {
      key: 'move',
      label: (
        <span
          tabIndex={0}
          role="button"
          onClick={(ev) => {
            handlerMoving(
              [props.uuid],
              [{ title: props.title, type: props.type }],
              setMoving,
            );
          }}
          onKeyDown={() => {
            setRenameModal(true);
            setRenameItem({ ...props });
          }}
        >
          <Space>
            <DoubleRightOutlined rev="" />
            <FormattedMessage id="Move" defaultMessage="Move" />
          </Space>
        </span>
      ),
    },
    {
      key: 'delete',
      label: (
        <span
          tabIndex={0}
          role="button"
          onClick={() => {
            setDeleteItems([props]);
            setOpenDeleteModal(true);
          }}
          onKeyDown={() => {
            setDeleteItems([props]);
            setOpenDeleteModal(true);
          }}
        >
          <Space>
            <DeleteOutlined rev="" />
            <FormattedMessage id="Delete" defaultMessage="Delete" />
          </Space>
        </span>
      ),
      danger: true,
    },
  ];

  const handleSelected = (e, obj) => {
    clickSelectHandler(e, rowSelection, obj, navigate);
  };
  return (
    <>
      <Dropdown menu={{ items }} trigger={['contextMenu']}>
        <div>
          {type === CONTENT_TYPES.FOLDER ? (
            <FolderCard
              {...props}
              {...{ isSelected, handleSelected, dropdownItems: items }}
            />
          ) : (
            <FileCard
              disabled={disabled}
              {...props}
              {...{ isSelected, handleSelected }}
            />
          )}
        </div>
      </Dropdown>
    </>
  );
};
export default AnimamCard;
