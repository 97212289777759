import { apiHeaders } from '../../api';
import { API_URLS, FILE_TYPES } from '../../config';
import { auth } from '../utils';
export const UPLOADFILE = 'UPLOADFILE';
export const uploadFile = (
  access_token,
  title,
  destination,
  file,
  language,
  functions,
) => {
  return async (dispatch) => {
    dispatch({
      type: `${UPLOADFILE}_PENDING`,
    });
    try {
      const data = new FormData();
      data.append('title', title);
      data.append('type', FILE_TYPES[file.type] ?? 1);
      data.append('parent', destination);
      data.append('file', file);
      data.append('language', language);
      const uploadData =
        (await apiHeaders({
          ...auth(access_token),
          'Content-Type': 'multipart/form-data',
        }).post(`${API_URLS.UPLOAD_FILE}`, data)) || null;
      dispatch({
        type: `${UPLOADFILE}_SUCCESS`,
        payload: uploadData.data,
        functions,
      });
    } catch (error) {
      dispatch({
        type: `${UPLOADFILE}_ERROR`,
        payload: error,
        functions,
      });
    }
  };
};
