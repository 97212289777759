import React from 'react';
import { TranscriptionTabComponent } from './TranscriptionTabComponent';
import { FormattedMessage } from 'react-intl';

const getTranscriptionTab = (
  fileMetadataState,
  fileState,
  srtArray,
  selectedTab,
) => {
  const key = 'transcription';
  return fileMetadataState.metadata?.format?.filename ||
    srtArray.length > 0 ||
    fileState.data?.transkripzioa
    ? [
        {
          label: (
            <FormattedMessage
              id="Transcription"
              defaultMessage="Transcription"
            />
          ),
          key: key,
          children: selectedTab === key && (
            <TranscriptionTabComponent
              fileMetadataState={fileMetadataState}
              fileState={fileState}
              srtArray={srtArray.slice(1)}
            />
          ),
        },
      ]
    : [];
};

export default getTranscriptionTab;
