import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  FolderFilled,
  EditOutlined,
  DeleteOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';

import { clickSelectHandler } from '../../helpers/utils';

import { Table, Col, Button, Space } from 'antd';
import { handlerMoving } from '../../helpers/contentActions';
import { CONTENT_TYPE_ICONS } from '../../config/constants';

const ContentTable = ({
  dataSource,
  setSortOrder,
  rowSelection,
  renaming,
  setMoving,
  moving,
  deleting,
}) => {
  const { setOpenDeleteModal, setDeleteItems } = deleting;
  const { setRenameItem, setRenameModal } = renaming;
  const handleRename = (record) => {
    setRenameItem({
      ...record,
    });
    setRenameModal(true);
  };
  const columns = [
    // {
    //   title: '',
    //   dataIndex: 'photo',
    //   align: 'center',
    //   render: (photo) => (photo ? <Image width={70} src={photo} /> : ''),
    // },
    {
      title: '',
      dataIndex: 'type',
      align: 'center',
      render: (type) =>
        type === 'folder' ? (
          <FolderFilled rev="" style={{ fontSize: '2.4rem' }} />
        ) : Object.keys(CONTENT_TYPE_ICONS).includes(type) ? (
          CONTENT_TYPE_ICONS[type]('1.7rem')
        ) : (
          CONTENT_TYPE_ICONS['else']
        ),
    },
    {
      title: <FormattedMessage id="Title" defaultMessage="Title" />,
      dataIndex: 'title',
      sorter: true,
    },
    {
      title: <FormattedMessage id="Description" defaultMessage="Description" />,
      dataIndex: 'description',
    },
    {
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      key: 'operation',
      fixed: 'right',
      render: (text, record) => (
        <Space.Compact>
          <Button
            disabled={moving.length}
            icon={<EditOutlined rev="" />}
            onClick={() => handleRename(record)}
          >
            <Space>
              <FormattedMessage id="Rename" defaultMessage="Rename" />
            </Space>
          </Button>
          <Button
            icon={<DoubleRightOutlined rev="" />}
            onClick={(e) => {
              handlerMoving(
                [record.uuid],
                [{ title: record.title, type: record.type }],
                setMoving,
              );
            }}
          >
            <Space>
              <FormattedMessage id="Move" defaultMessage="Move" />
            </Space>
          </Button>
          <Button
            icon={<DeleteOutlined rev="" />}
            onClick={() => {
              setDeleteItems([record]);
              setOpenDeleteModal(true);
            }}
            danger
          >
            <Space>
              <FormattedMessage id="Delete" defaultMessage="Delete" />
            </Space>
          </Button>
        </Space.Compact>
      ),
    },
  ];
  const navigate = useNavigate();

  const onChangeTable = (pagination, filters, sorter) => {
    setSortOrder(sorter.order);
    // setData([...dataSource].sort(folderFilesSorter(sorter.order)));
  };

  const onClickRow = (ev, record) => {
    clickSelectHandler(ev, rowSelection, record, navigate);
  };

  const handleRow = (record, index) => {
    return {
      onClick: (event) => onClickRow(event, record),
    };
  };

  return (
    <Col span={24}>
      <Table
        columns={columns}
        rowSelection={rowSelection}
        onRow={handleRow}
        dataSource={dataSource}
        rowKey={'uuid'}
        pagination={false}
        onChange={onChangeTable}
      />
    </Col>
  );
};

export default ContentTable;
