import React from 'react';
import { Breadcrumb, theme } from 'antd';
import { HomeOutlined, RightOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
const AntBreadcrumb = ({ extras = [] }) => {
  const { token: globalToken } = theme.useToken();
  const homeBreadcrumb = [
    {
      path: '/home',
      title: <HomeOutlined style={{ fontSize: '.8em' }} rev="" />,
    },
  ];
  const { fileid } = useParams();

  const contentsState = useSelector((state) => state.contents);
  const fileState = useSelector((state) => state.getFile);

  const fileBreadcrumb = fileid
    ? fileState.breadcrumb.slice(1).map((b) => {
        return {
          title: <span>{b.title}</span>,
          path: `/folders/${b.uuid}/`,
        };
      })
    : [];
  const items = [
    ...homeBreadcrumb,
    ...contentsState.breadcrumb.slice(1).map((b) => {
      return {
        title: <span>{b.title}</span>,
        path: `/folders/${b.uuid}/`,
      };
    }),
    ...fileBreadcrumb,
    ...extras,
  ];
  const itemRender = (route, items, moving) => {
    const last = items.indexOf(route) === items.length - 1;
    const movingCurrent = items.indexOf(route) === items.length - 2;
    return last || (moving && movingCurrent) ? (
      <span>{route.title}</span>
    ) : (
      <Link style={{ height: 'auto' }} to={route.path}>
        {route.title}
      </Link>
    );
  };
  return (
    <Breadcrumb
      style={{
        fontSize: '2em',
        paddingBottom: globalToken.paddingLG,
      }}
      separator={<RightOutlined style={{ fontSize: '.6em' }} rev="" />}
      items={items}
      itemRender={(route, params, items, paths) =>
        itemRender(route, items, extras.length)
      }
    />
  );
};

export default AntBreadcrumb;
