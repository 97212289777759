import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login_renew, getLanguage } from '../../store/actions';
import Cookies from 'js-cookie';

const initialLoader = (App) => {
  return (props) => {
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.login);
    const auth_token = Cookies.get('auth_token');
    if (auth_token) {
      if (
        !loginState.loading &&
        !loginState.loaded &&
        !loginState.sessionData?.token
      )
        dispatch(login_renew(auth_token));
    }
    useEffect(() => {
      // @ts-ignore
      dispatch(getLanguage());
    }, [dispatch]);
    return <App {...props}>{props.children}</App>;
  };
};

export default initialLoader;
