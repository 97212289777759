import { CONTENT_TYPES } from '../config/constants';
function slugify(str) {
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const folderFilesSorter = (order, field) => {
  if (order === 'descend') {
    return (a, b) => {
      if (a.type === CONTENT_TYPES.FOLDER && b.type !== CONTENT_TYPES.FOLDER) {
        return 1;
      } else if (
        a.type !== CONTENT_TYPES.FOLDER &&
        b.type === CONTENT_TYPES.FOLDER
      ) {
        return 1;
      } else {
        return b[field].localeCompare(a[field]);
      }
    };
  } else {
    return (a, b) => {
      if (a.type === CONTENT_TYPES.FOLDER && b.type !== CONTENT_TYPES.FOLDER) {
        return -1;
      } else if (
        a.type !== CONTENT_TYPES.FOLDER &&
        b.type === CONTENT_TYPES.FOLDER
      ) {
        return 1;
      } else {
        return a[field].localeCompare(b[field]);
      }
    };
  }
};

const clickSelectHandler = (ev, rs, record, navigate) => {
  if (ev.detail === 2) {
    if (record.type === CONTENT_TYPES.FOLDER) {
      rs.onChange([]);
      navigate(`/folders/${record.uuid}`);
    } else {
      rs.onChange([]);
      navigate(`/file/${record.uuid}`);
    }
  } else if (ev.ctrlKey === true || ev.metaKey === true) {
    if (rs.selectedRowKeys.includes(record.uuid)) {
      rs.onChange([...rs.selectedRowKeys.filter((rk) => rk !== record.uuid)]);
    } else {
      rs.onChange([...rs.selectedRowKeys, record.uuid]);
    }
  } else {
    if (
      rs.selectedRowKeys.length === 1 &&
      rs.selectedRowKeys.includes(record.uuid)
    ) {
      rs.onChange([]);
    } else {
      rs.onChange([record.uuid]);
    }
  }
};

const getReadableFileSizeString = (fileSizeInBytes) => {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  if (fileSizeInBytes) {
    do {
      fileSizeInBytes /= 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  } else {
    return fileSizeInBytes;
  }
};

export {
  slugify,
  getItem,
  folderFilesSorter,
  clickSelectHandler,
  getReadableFileSizeString,
};
