import React, { useRef, useState } from 'react';
import { Input, Modal, Space, Tag } from 'antd';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createFolder } from '../../store/actions';

const ModalCreateFolder = ({ openCreateModal, setOpenCreateModal }) => {
  const messages = defineMessages({
    sidebar_creating: {
      id: 'sidebar.creating',
      defaultMessage: 'You are going to create a folder inside {destination}',
    },
  });
  const createInputRef = useRef(null);
  const dispatch = useDispatch();
  const { folderid } = useParams();
  const [createItemTitle, setCreateItemTitle] = useState('');

  const createFolderState = useSelector((state) => state.createFolder);
  const contentsState = useSelector((state) => state.contents);
  const loginToken = useSelector((state) => state.login.sessionData.token);

  const makeCreateEffective = () => {
    if (createItemTitle) {
      dispatch(
        createFolder(loginToken, createItemTitle, folderid ? folderid : null),
      ).then(() => {
        setOpenCreateModal(false);
      });
    }
    setCreateItemTitle('');
  };

  return (
    <Modal
      title={
        <FormattedMessage
          id="Create a new folder"
          defaultMessage="Create a new folder"
        />
      }
      centered
      open={openCreateModal}
      onOk={() => {
        makeCreateEffective();
      }}
      confirmLoading={createFolderState.loading}
      onCancel={() => {
        setOpenCreateModal(false);
      }}
      afterOpenChange={(open) => open && createInputRef.current.focus()}
    >
      <Space direction="vertical" size={15}>
        <FormattedMessage
          {...messages.sidebar_creating}
          values={{
            destination: (
              <Tag>
                {contentsState.breadcrumb.slice(-1).length > 0 ? (
                  contentsState.breadcrumb.slice(-1)[0].title
                ) : (
                  <FormattedMessage id="Home" defaultMessage="Home" />
                )}
              </Tag>
            ),
          }}
        />
        <Input
          id="create_input"
          ref={createInputRef}
          type="text"
          value={createItemTitle}
          onChange={(e) => {
            setCreateItemTitle(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              makeCreateEffective();
            }
          }}
        />
      </Space>
    </Modal>
  );
};

export default ModalCreateFolder;
