import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import srtParser2 from 'srt-parser-2';

import { ActionsComponent, Breadcrumb } from '../components';
import {
  getContents,
  getFile,
  getFileMetadata,
  getFileSrt,
} from '../store/actions';

import { Col, Empty, Row, Tabs } from 'antd';
import Cookies from 'js-cookie';
import notifcationMessages from '../helpers/hocs/notificationMessages';
import {
  getMetadataTab,
  getStreamsTab,
  getDescriptorsTab,
  getTranscriptionTab,
  getAdituTaskTab,
} from '../components/FileTabs';

const File = ({ apiMessage, contextHolder }) => {
  const dispatch = useDispatch();
  const parser = new srtParser2();
  const defaultTab = 'metadata';

  const { fileid } = useParams();
  const loginToken = useSelector((state) => state.login.sessionData.token);
  const fileState = useSelector((state) => state.getFile);
  const fileMetadataState = useSelector((state) => state.getFileMetadata);
  const fileSrtState = useSelector((state) => state.getFileSrt);
  const contentsState = useSelector((state) => state.contents);

  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [srtArray, setSrtArray] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [moving, setMoving] = useState(Cookies.get('__mv') ?? []);
  const [renameModal, setRenameModal] = useState(false);
  const [renameItem, setRenameItem] = useState({
    title: '',
    uuid: '',
    type: '',
  });

  const deleteContext = {
    openDeleteModal,
    setOpenDeleteModal,
    deleteItems,
    setDeleteItems,
  };
  const renameContext = {
    renameModal,
    setRenameModal,
    renameItem,
    setRenameItem,
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    fileid && loginToken && dispatch(getFile(loginToken, fileid));
    fileid && loginToken && dispatch(getFileMetadata(loginToken, fileid));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileid, loginToken]);

  useEffect(() => {
    loginToken &&
      !contentsState.loaded &&
      !contentsState.loading &&
      fileState.loaded &&
      !fileState.loading &&
      fileState.data.parent &&
      dispatch(getContents(loginToken, fileState.data.parent));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileState.loaded, loginToken]);

  React.useEffect(() => {
    if (fileState.loaded) dispatch(getFileSrt(`${fileState.data?.aditu_srt}`));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileState.loaded, fileState.data, loginToken]);

  React.useEffect(() => {
    if (fileSrtState.loaded) setSrtArray(parser.fromSrt(fileSrtState.data));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSrtState.loaded, fileSrtState.data]);

  return (
    <div>
      {contextHolder}

      <Row justify="center" align="middle">
        <Col span={24}>
          {!hasSelected && !moving.length && <Breadcrumb />}
          {(hasSelected || moving.length > 0) && (
            <ActionsComponent
              rowSelection={rowSelection}
              moving={moving}
              setMoving={setMoving}
              apiMessage={apiMessage}
              renaming={renameContext}
              deleting={deleteContext}
            />
          )}
        </Col>
      </Row>
      {loginToken ? (
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <Tabs
              onTabClick={(id) => setSelectedTab(id)}
              activeKey={selectedTab}
              type="card"
              items={[
                ...getMetadataTab(fileMetadataState, fileState, selectedTab),
                ...getStreamsTab(
                  fileMetadataState.metadata?.streams,
                  selectedTab,
                ),
                ...getAdituTaskTab(fileState, selectedTab),
                ...getTranscriptionTab(
                  fileMetadataState,
                  fileState,
                  srtArray,
                  selectedTab,
                ),
                ...getDescriptorsTab(fileState, selectedTab),
                // {
                //   label: 'All the data',
                //   key: 'all-data',
                //   children: Object.entries(fileState.data).map((obj, key) => (
                //     <li key={key}>
                //       <strong>{obj[0]}:</strong> {JSON.stringify(obj[1])}
                //     </li>
                //   )),
                // },
              ]}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Empty
              description={
                <>
                  You are not logged in.
                  <br />
                  Please go to the <Link to="/login">login page</Link>
                </>
              }
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default notifcationMessages(File);
