import React from 'react';
import { file_messages } from '../../pages/file_messages';
import { useIntl } from 'react-intl';
import { DATETIME_FORMAT } from '../../config/constants';
import { getReadableFileSizeString } from '../../helpers/utils';

const ListItem = ({ o_key, data, format = 'text' }) => {
  const intl = useIntl();
  const formatters = {
    text: (d) => d,
    datetime: (d) => intl.formatDate(d, DATETIME_FORMAT),
    filesize: (d) => getReadableFileSizeString(d),
  };
  const formatter = formatters.hasOwnProperty(format)
    ? formatters[format]
    : formatters.text;

  return (
    <>
      <strong>{intl.formatMessage(file_messages[o_key])}</strong>{' '}
      {formatter(data)}
    </>
  );
};

export default ListItem;
