import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { Navbar } from './components';
import Sidebar from './components/Sidebar/Sidebar';
import './formatjs-polyfills';
import initialLoader from './helpers/hocs/initialLoader';
import messages_en from './locales/en/en.json';
import messages_es from './locales/es/es.json';
import messages_eu from './locales/eu/eu.json';
// Eslint disables the following line
import './theme/global.css';
import { tokens } from './theme/tokens.js';
import { useNavigate } from 'react-router-dom';

import { ConfigProvider, Layout, theme } from 'antd';
import { AVAILABLE_LANGUAGES } from './config';

const { Content } = Layout;

const messages = {
  eu: messages_eu,
  es: messages_es,
  en: messages_en,
};

function App(props) {
  const { langCode } = useSelector((state) => state.language);
  const navigate = useNavigate();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const antl = AVAILABLE_LANGUAGES.find((al) => al.code === langCode).antl;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <IntlProvider locale={langCode} messages={messages[langCode]}>
      <ConfigProvider theme={tokens} locale={antl}>
        <Layout>
          <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
          <Layout style={{ marginLeft: collapsed ? '72px' : '192px' }}>
            <Navbar navigate={navigate} />
            <Content
              style={{
                padding: '1.5rem',
                minHeight: '100vh',
                background: colorBgContainer,
                overflow: 'auto',
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </ConfigProvider>
    </IntlProvider>
  );
}

export default initialLoader(App);
