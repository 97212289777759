import { getCookieOptions } from '../../helpers/cookies';
import { LOGIN, LOGIN_RENEW, LOGOUT } from '../actions/loginSession';

import Cookies from 'js-cookie';

const initialState = {
  sessionData: { token: null },
  loading: false,
  loaded: false,
  error: null,
};

const loginSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN_RENEW}_PENDING`:
    case `${LOGIN}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${LOGIN}_SUCCESS`:
      Cookies.set(
        'auth_token',
        action.payload.data.token,
        getCookieOptions({
          expires: new Date(action.payload.data.expiration_date),
        }),
      );
      return {
        ...state,
        sessionData: action.payload.data,
        loading: false,
        loaded: true,
      };
    case `${LOGIN_RENEW}_SUCCESS`:
      return {
        ...state,
        sessionData: action.payload.data,
        loading: false,
        loaded: true,
      };
    case `${LOGIN_RENEW}_ERROR`:
    case `${LOGIN}_ERROR`:
      Cookies.remove('auth_token');
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };
    case `${LOGOUT}_SUCCESS`:
    case `${LOGOUT}_ERROR`:
      Cookies.remove('auth_token');
      return {
        ...state,
        sessionData: { token: null },
      };
    // Default case
    default:
      return state;
  }
};

export default loginSessionReducer;
