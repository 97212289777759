import { defineMessages } from 'react-intl';

export const file_messages = defineMessages({
  title: {
    id: 'title',
    defaultMessage: 'Title:',
  },
  added: {
    id: 'added',
    defaultMessage: 'added:',
  },
  modified: {
    id: 'modified',
    defaultMessage: 'modified:',
  },
  duration: {
    id: 'duration',
    defaultMessage: 'duration:',
  },
  file_type: {
    id: 'file_type',
    defaultMessage: 'file_type:',
  },
  size: {
    id: 'size',
    defaultMessage: 'size:',
  },
  created: {
    id: 'created',
    defaultMessage: 'created:',
  },
  filename: {
    id: 'filename',
    defaultMessage: 'filename:',
  },
  nb_streams: {
    id: 'nb_streams',
    defaultMessage: 'nb_streams:',
  },

  format_name: {
    id: 'format_name',
    defaultMessage: 'format_name:',
  },
  start_time: {
    id: 'start_time',
    defaultMessage: 'start_time:',
  },
  format_long_name: {
    id: 'format_long_name',
    defaultMessage: 'format_long_name:',
  },
  bit_rate: {
    id: 'bit_rate',
    defaultMessage: 'bit_rate:',
  },

  codec_name: {
    id: 'codec_name',
    defaultMessage: 'codec_name:',
  },
  codec_type: {
    id: 'codec_type',
    defaultMessage: 'codec_type:',
  },
  sample_rate: {
    id: 'sample_rate',
    defaultMessage: 'sample_rate:',
  },
  channels: {
    id: 'channels',
    defaultMessage: 'channels:',
  },
  channel_layout: {
    id: 'channel_layout',
    defaultMessage: 'channel_layout:',
  },
  width: {
    id: 'width',
    defaultMessage: 'width:',
  },
  height: {
    id: 'height',
    defaultMessage: 'height:',
  },
  closed_captions: {
    id: 'closed_captions',
    defaultMessage: 'closed_captions:',
  },
  r_frame_rate: {
    id: 'r_frame_rate',
    defaultMessage: 'r_frame_rate:',
  },
  max_bit_rate: {
    id: 'max_bit_rate',
    defaultMessage: 'max_bit_rate:',
  },
  bits_per_raw_sample: {
    id: 'bits_per_raw_sample',
    defaultMessage: 'bits_per_raw_sample:',
  },
  tags: {
    id: 'tags',
    defaultMessage: 'tags:',
  },
  language: {
    id: 'language',
    defaultMessage: 'language:',
  },
  handler_name: {
    id: 'handler_name',
    defaultMessage: 'handler_name:',
  },
});
