import { UPLOADFILE } from '../actions/uploadFile';

const initialState = {
  data: {},
  message: '',
  loading: false,
  loaded: false,
  error: null,
};

const uploadFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${UPLOADFILE}_PENDING`:
      return {
        ...state,
        message: '',
        loading: true,
        loaded: false,
        error: null,
      };
    case `${UPLOADFILE}_SUCCESS`:
      action.functions.onSuccess(action.payload.message);
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        loading: false,
        loaded: true,
      };
    case `${UPLOADFILE}_ERROR`:
      action.functions.onError(action.payload.message);
      return {
        ...state,
        data: {},
        error: action.payload.message,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default uploadFileReducer;
