import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { UserOutlined } from '@ant-design/icons';

import {
  Button,
  Spin,
  Form,
  Input,
  Row,
  Col,
  Result,
  Alert,
  theme,
} from 'antd';
import { requestPasswordReset } from '../store/actions';

const PasswordResetRequest = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { token: globalTokens } = theme.useToken();
  // @ts-ignore
  const usersState = useSelector((state) => state.users);
  const messages = defineMessages({
    required_username: {
      id: 'login.required_username',
      defaultMessage: 'Please input your Username!',
    },
    required_password: {
      id: 'login.required_password',
      defaultMessage: 'Please input your Password!',
    },
    username: {
      id: 'login.username',
      defaultMessage: 'Username',
    },
    request_reset_password_info: {
      id: 'request_reset_password.info',
      defaultMessage: '',
    },
  });
  const onFinish = (values) => {
    // @ts-ignore
    dispatch(requestPasswordReset(values.username));
  };
  return (
    <Row gutter={16}>
      <Col span={6} offset={9}>
        <h1>
          <FormattedMessage
            id="Reset your Password"
            defaultMessage="Reset your Password"
          />
        </h1>
      </Col>
      <Col span={10} offset={7} style={{ marginBottom: globalTokens.marginMD }}>
        <Alert
          message={intl.formatMessage(messages.request_reset_password_info)}
          type="info"
          showIcon
        />
      </Col>
      {usersState?.requestpasswordreset?.error && (
        <Col span={24}>
          <Result
            status="error"
            title={usersState?.requestpasswordreset?.error.message}
          />
        </Col>
      )}
      <Col span={6} offset={9}>
        <Spin spinning={usersState?.requestpasswordreset?.loading}>
          <Form className="login-form" onFinish={onFinish}>
            <Form.Item
              name="username"
              validateStatus={
                usersState?.requestpasswordreset?.error ? 'error' : ''
              }
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.required_username),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" rev="" />}
                placeholder={intl.formatMessage(messages.username)}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                <FormattedMessage id="Request" defaultMessage="Request" />
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
};

export default PasswordResetRequest;
