import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { UserOutlined, LockOutlined } from '@ant-design/icons';

import {
  Button,
  Spin,
  Form,
  Input,
  Row,
  Col,
  Result,
  Alert,
  theme,
} from 'antd';
import { resetPassword } from '../store/actions';
import { useParams } from 'react-router-dom';

const PasswordReset = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { token: globalTokens } = theme.useToken();
  const { token } = useParams();
  // @ts-ignore
  const usersState = useSelector((state) => state.users);
  const messages = defineMessages({
    required_username: {
      id: 'login.required_username',
      defaultMessage: 'Please input your Username!',
    },
    required_password: {
      id: 'login.required_password',
      defaultMessage: 'Please input your Password!',
    },
    username: {
      id: 'login.username',
      defaultMessage: 'Username',
    },
    password: {
      id: 'login.password',
      defaultMessage: 'Password',
    },
    reset_password_info: {
      id: 'reset_password.info',
      defaultMessage: '',
    },
    repeatpassword: {
      id: 'repeatpassword',
      defaultMessage: 'Repeat password',
    },
    different_password: {
      id: 'different_password',
      defaultMessage: 'The new password that you entered do not match!',
    },
  });
  const onFinish = (values) => {
    if (values.password !== values.repeatpassword) {
    } else {
      // @ts-ignore
      dispatch(resetPassword(values.username, values.password, token));
    }
  };
  return (
    <Row gutter={16}>
      <Col span={6} offset={9}>
        <h1>
          <FormattedMessage
            id="Request your Password reset"
            defaultMessage="Request your Password reset"
          />
        </h1>
      </Col>
      <Col span={10} offset={7} style={{ marginBottom: globalTokens.marginMD }}>
        <Alert
          message={intl.formatMessage(messages.reset_password_info)}
          type="info"
          showIcon
        />
      </Col>
      {usersState?.passwordreset?.error && (
        <Col span={24}>
          <Result
            status="error"
            title={usersState?.passwordreset?.error.message}
          />
        </Col>
      )}
      <Col span={6} offset={9}>
        <Spin spinning={usersState?.passwordreset?.loading}>
          <Form className="login-form" onFinish={onFinish}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.required_username),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" rev="" />}
                placeholder={intl.formatMessage(messages.username)}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.required_password),
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" rev="" />}
                type="password"
                placeholder={intl.formatMessage(messages.password)}
              />
            </Form.Item>
            <Form.Item
              name="repeatpassword"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.required_password),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        intl.formatMessage(messages.different_password),
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" rev="" />}
                type="password"
                placeholder={intl.formatMessage(messages.repeatpassword)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                <FormattedMessage
                  id="Reset password"
                  defaultMessage="Reset password"
                />
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
};

export default PasswordReset;
