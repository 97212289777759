import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AVAILABLE_LANGUAGES } from '../../config';
import { Button, Dropdown } from 'antd';
import { TranslationOutlined } from '@ant-design/icons';
import { setLanguage } from '../../store/actions/language';

const LanguageSelector = () => {
  const selectedLangCode = useSelector((state) => state?.language?.langCode);
  const dispatch = useDispatch();

  const items = AVAILABLE_LANGUAGES.map((language, i) => ({
    key: language.code,
    label: (
      <Button onClick={() => dispatch(setLanguage(language.code))} type="text">
        {language.name}
      </Button>
    ),
  }));

  return (
    <Dropdown menu={{ items }} placement="bottom">
      <Button type="ghost">
        <TranslationOutlined rev="" />
        <span>{selectedLangCode}</span>
      </Button>
    </Dropdown>
  );
};

export default LanguageSelector;
