import { List } from 'antd';
import { FormattedMessage } from 'react-intl';
import StreamCard from '../StreamCard/StreamCard';

const getStreamsTab = (streams, selectedTab) => {
  const key = 'streams';
  return streams
    ? [
        {
          label: <FormattedMessage id="Streams" defaultMessage={'Streams'} />,
          key: key,
          children: streams && selectedTab === key && (
            <List
              grid={{ gutter: 16, column: 4 }}
              dataSource={streams}
              renderItem={(s) => (
                <List.Item>
                  <StreamCard stream={s} />
                </List.Item>
              )}
            />
          ),
        },
      ]
    : [];
};

export default getStreamsTab;
