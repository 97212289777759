import { GETFILEMETADATA } from '../actions/getFile';

const initialState = {
  metadata: {},
  loading: false,
  loaded: false,
  error: null,
};

const getFileMetadataReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GETFILEMETADATA}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${GETFILEMETADATA}_SUCCESS`:
      return {
        ...state,
        metadata: action.payload.data,
        loading: false,
        loaded: true,
      };
    case `${GETFILEMETADATA}_ERROR`:
      return {
        ...state,
        metadata: {},
        error: action.payload.message,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default getFileMetadataReducer;
