import { message } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

const notifcationMessages = (Component) => {
  return (props) => {
    const messages = defineMessages({
      actions_moved: {
        id: 'actions.moved',
        defaultMessage: 'Moved into {destination}',
      },
    });
    // @ts-ignore
    const contentsState = useSelector((state) => state.contents);
    // @ts-ignore
    const cFs = useSelector((state) => state.createFolder);
    // @ts-ignore
    // const createContentState = useSelector((state) => state.createContent);
    // @ts-ignore
    const eFs = useSelector((state) => state.editFolder);
    // @ts-ignore
    const eCs = useSelector((state) => state.editContent);
    // @ts-ignore
    const dFs = useSelector((state) => state.deleteFolder);
    // @ts-ignore
    const dCs = useSelector((state) => state.deleteContent);

    const cFs_ref = useRef(cFs);
    // const createContentState_ref = useRef(createContentState);
    const eCs_ref = useRef(eCs);
    const eFs_ref = useRef(eFs);
    const dCs_ref = useRef(dCs);
    const dFs_ref = useRef(dFs);
    const [apiMessage, contextHolder] = message.useMessage();

    useEffect(() => {
      if (cFs_ref.current.loading && cFs.loaded) {
        apiMessage.open({
          type: 'success',
          content: (
            <FormattedMessage
              id="Created successfully"
              defaultMessage="Created successfully"
            />
          ),
        });
      }
      if (
        (eFs_ref.current.loading && eFs.loaded && !eFs.moved) ||
        (eCs_ref.current.loading && eCs.loaded && !eCs.moved)
      ) {
        apiMessage.open({
          type: 'success',
          content: (
            <FormattedMessage
              id="Modification done successfully"
              defaultMessage="Modification done successfully"
            />
          ),
        });
      }
      if (
        (eFs_ref.current.loading && eFs.loaded && eFs.moved) ||
        (eCs_ref.current.loading && eCs.loaded && eCs.moved)
      ) {
        apiMessage.open({
          type: 'success',
          content: (
            <FormattedMessage
              {...messages.actions_moved}
              values={{
                destination: (
                  <>
                    {contentsState.breadcrumb.slice(-1).length > 0 ? (
                      <>{contentsState.breadcrumb.slice(-1)[0].title}</>
                    ) : (
                      <>Home</>
                    )}
                  </>
                ),
              }}
            />
          ),
        });
      }
      if (
        (dFs_ref.current.loading && dFs.loaded) ||
        (dCs_ref.current.loading && dCs.loaded)
      ) {
        apiMessage.open({
          type: 'success',
          content: (
            <FormattedMessage
              id="Deleted successfully"
              defaultMessage="Deleted successfully"
            />
          ),
        });
      }

      // createContentState_ref.current = createContentState;
      cFs_ref.current = cFs;
      eCs_ref.current = eCs;
      eFs_ref.current = eFs;
      dCs_ref.current = dCs;
      dFs_ref.current = dFs;
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cFs, eFs, eCs, dFs, dCs]);
    return (
      <Component
        apiMessage={apiMessage}
        contextHolder={contextHolder}
        {...props}
      >
        {props.children}
      </Component>
    );
  };
};
export default notifcationMessages;
