export const tokens = {
  token: {
    colorPrimary: '#3a7e7e',
    colorPrimaryLight: '#E0F6F6',
    colorPrimaryDarker: '#155b5b',
    colorPrimaryBg: '#bde4e4',
    colorPrimaryBgHover: '#ececec',
    colorLink: '#155B5B',
  },
  components: {
    Menu: {
      itemSelectedColor: '#155B5B',
      colorBgContainer: '#155b5b',
      colorPrimary: 'white',
      colorText: 'white',
    },
  },
};
