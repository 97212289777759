import { Card, Flex, Progress } from 'antd';
import { FormattedMessage } from 'react-intl';

const getDescriptorsTab = (fileState, selectedTab) => {
  const key = 'descriptors';
  return fileState.loaded &&
    (fileState.data?.thematic_descriptors ||
      fileState.data?.specific_descriptors)
    ? [
        {
          label: (
            <FormattedMessage id="Descriptors" defaultMessage={'Descriptors'} />
          ),
          key: key,
          children: fileState.data && selectedTab === key && (
            <Flex justify="space-between">
              {fileState.data?.thematic_descriptors &&
                Object.entries(fileState.data.thematic_descriptors).map(
                  ([k, d]) => {
                    return (
                      <Card title={k} style={{ width: '30%' }}>
                        {d.map((g) => {
                          return (
                            <div>
                              <strong>{g[0]}</strong>
                              <p>
                                <Progress
                                  percent={(g[1] * 100).toFixed(4)}
                                  format={(percent) =>
                                    `${(percent / 100).toFixed(4)}`
                                  }
                                />
                              </p>
                            </div>
                          );
                        })}
                      </Card>
                    );
                  },
                )}
              {fileState.data?.specific_descriptors &&
                Object.entries(fileState.data.specific_descriptors).map(
                  ([k, d]) => {
                    return (
                      <Card title={k} style={{ width: '30%' }}>
                        {d.map((g) => {
                          return (
                            <div>
                              <strong>{g[0]}</strong>
                              <p>
                                <Progress
                                  percent={(g[1] * 100).toFixed(4)}
                                  format={(percent) =>
                                    `${(percent / 100).toFixed(4)}`
                                  }
                                />
                              </p>
                            </div>
                          );
                        })}
                      </Card>
                    );
                  },
                )}
            </Flex>
          ),
        },
      ]
    : [];
};

export default getDescriptorsTab;
