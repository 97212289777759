import es_ES from 'antd/es/locale/es_ES';
import eu_ES from 'antd/es/locale/eu_ES';
import en_US from 'antd/locale/en_US';

export const DEFAULT_LANGUAGE = 'eu';
export const isMultiLanguage = true;

export const AVAILABLE_LANGUAGES = [
  {
    code: 'eu',
    name: 'Euskara',
    antl: eu_ES,
  },
  {
    code: 'es',
    name: 'Español',
    antl: es_ES,
  },
  {
    code: 'en',
    name: 'English',
    antl: en_US,
  },
];
