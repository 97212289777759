import React, { useRef } from 'react';
import { Input, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';

const ModalRename = ({ makeRenameEffective, renaming, loading }) => {
  const { renameModal, setRenameModal, renameItem, setRenameItem } = renaming;
  const renameInputRef = useRef(null);
  return (
    <Modal
      title={
        <FormattedMessage id="Rename element" defaultMessage="Rename element" />
      }
      centered
      open={renameModal}
      onOk={() => {
        makeRenameEffective();
      }}
      confirmLoading={loading}
      onCancel={() => {
        setRenameModal(false);
      }}
      afterOpenChange={(open) => open && renameInputRef.current.focus()}
    >
      <Input
        id="rename_input"
        ref={renameInputRef}
        type="text"
        value={renameItem.title}
        onChange={(e) => {
          setRenameItem({ ...renameItem, title: e.target.value });
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            makeRenameEffective();
          }
        }}
      />
    </Modal>
  );
};
export default ModalRename;
