import api from '../../api';
import { API_URLS } from '../../config';

export const REQUESTPASSWORDRESET = 'REQUESTPASSWORDRESET';
export const requestPasswordReset = (user) => {
  return async (dispatch) => {
    dispatch({
      type: `${REQUESTPASSWORDRESET}_PENDING`,
    });
    try {
      const response =
        (await api.post(API_URLS.REQUESTPASSWORDRESET, { user })) || null;
      dispatch({
        type: `${REQUESTPASSWORDRESET}_SUCCESS`,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: `${REQUESTPASSWORDRESET}_ERROR`,
        payload: error,
      });
    }
  };
};
export const PASSWORDRESET = 'PASSWORDRESET';
export const resetPassword = (user, password, token) => {
  return async (dispatch) => {
    dispatch({
      type: `${PASSWORDRESET}_PENDING`,
    });
    try {
      const response =
        (await api.post(API_URLS.PASSWORDRESET, { user, password, token })) ||
        null;
      dispatch({
        type: `${PASSWORDRESET}_SUCCESS`,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: `${PASSWORDRESET}_ERROR`,
        payload: error,
      });
    }
  };
};
