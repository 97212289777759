import { theme } from 'antd';

const isCurrentText = (i, currentTime) =>
  i.startSeconds <= currentTime + 0.01 && currentTime - 0.01 <= i.endSeconds;

export const getCurrentTextStyle = (item, currentTime) => {
  const { token: globalToken } = theme.useToken();
  const isCurrent = isCurrentText(item, currentTime);
  return isCurrent ? { backgroundColor: globalToken.colorPrimaryLight } : {};
};

export const getCurrentSrtId = (items, currentTime) => {
  const currentSrtItem = items.find((i) => isCurrentText(i, currentTime));
  return currentSrtItem?.id || -1;
};
