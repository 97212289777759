import {
  Folders,
  File,
  About,
  Settings,
  Login,
  PasswordResetRequest,
  PasswordReset,
} from '../pages';

const ROUTES = {
  HOME: {
    path: 'home',
    Component: Folders,
  },
  ABOUT: {
    path: 'about',
    Component: About,
  },
  FOLDER: {
    path: 'folders/:folderid',
    Component: Folders,
  },
  FILE: {
    path: 'file/:fileid',
    Component: File,
  },
  LOGIN: {
    path: 'login',
    Component: Login,
  },
  PASSWORDRESET: {
    path: 'passwordreset',
    Component: PasswordResetRequest,
  },
  PASSWORDRESETTOKEN: {
    path: 'passwordreset/:token',
    Component: PasswordReset,
  },
  SETTINGS: {
    path: 'settings',
    Component: Settings,
  },
};

export default ROUTES;
