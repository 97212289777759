import { apiHeaders } from '../../api';
import { API_URLS } from '../../config';
import { auth } from '../utils';
export const DELETEFOLDER = 'DELETEFOLDER';
export const deleteFolder = (access_token, uuid) => {
  return async (dispatch) => {
    dispatch({
      type: `${DELETEFOLDER}_PENDING`,
    });
    try {
      const deleteData =
        (await apiHeaders(auth(access_token)).delete(
          `${API_URLS.FOLDERS}${uuid}`,
        )) || null;
      dispatch({
        type: `${DELETEFOLDER}_SUCCESS`,
        payload: deleteData.data,
      });
    } catch (error) {
      dispatch({
        type: `${DELETEFOLDER}_ERROR`,
        payload: error,
      });
    }
  };
};
