import { apiHeaders } from '../../api';
import { API_URLS } from '../../config';
import { auth } from '../utils';
export const CREATEFOLDER = 'CREATEFOLDER';
export const createFolder = (access_token, title, destination) => {
  return async (dispatch) => {
    dispatch({
      type: `${CREATEFOLDER}_PENDING`,
    });
    try {
      const createData =
        (await apiHeaders(auth(access_token)).post(`${API_URLS.FOLDERS}`, {
          title: title,
          type: 1,
          parent: destination,
        })) || null;
      dispatch({
        type: `${CREATEFOLDER}_SUCCESS`,
        payload: createData.data,
      });
    } catch (error) {
      dispatch({
        type: `${CREATEFOLDER}_ERROR`,
        payload: error,
      });
    }
  };
};
