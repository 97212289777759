import { combineReducers } from 'redux';

import languageReducer from './language';
// import userReducer from './user';
import usersReducer from './users';
import loginSessionReducer from './loginSession';
import contentsReducer from './contents';
import editFolderReducer from './editFolder';
import editContentReducer from './editContent';
import createFolderReducer from './createFolder';
import uploadFileReducer from './uploadFile';
import getFileReducer from './getFile';
import getFileMetadataReducer from './getFileMetadata';
import getFileSrtReducer from './getFileSrt';
import deleteFolderReducer from './deleteFolder';
import deleteContentReducer from './deleteContent';

const rootReducer = combineReducers({
  language: languageReducer,
  // user: userReducer,
  users: usersReducer,
  login: loginSessionReducer,
  contents: contentsReducer,
  createFolder: createFolderReducer,
  deleteFolder: deleteFolderReducer,
  editFolder: editFolderReducer,
  editContent: editContentReducer,
  deleteContent: deleteContentReducer,
  uploadFile: uploadFileReducer,
  getFile: getFileReducer,
  getFileMetadata: getFileMetadataReducer,
  getFileSrt: getFileSrtReducer,
});

export default rootReducer;
