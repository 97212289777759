import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  ClockCircleOutlined,
  StarOutlined,
  PlusOutlined,
  FolderAddOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';

import { ROUTES } from '../../config';
import { getItem } from '../../helpers/utils';
import './Sidebar.css';
import { ModalCreateFolder, ModalUploadFile } from '../Modals';
import { Layout, theme, Image, Menu, Button, Space, Popover } from 'antd';

const { Sider } = Layout;
const Sidebar = ({ collapsed, setCollapsed }) => {
  const intl = useIntl();

  const loginToken = useSelector((state) => state.login.sessionData.token);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const { token: globalToken } = theme.useToken();

  const messages = defineMessages({
    menu: {
      id: 'sidebar.menu',
      defaultMessage: 'Menu',
    },
    latest: {
      id: 'sidebar.latest',
      defaultMessage: 'Latest',
    },
    starred: {
      id: 'sidebar.starred',
      defaultMessage: 'Starred',
    },
  });
  const menuItems = [
    getItem(
      intl.formatMessage(messages.latest),
      'latest',
      <ClockCircleOutlined rev="" />,
    ),
    getItem(
      intl.formatMessage(messages.starred),
      'starred',
      <StarOutlined rev="" />,
    ),
  ];
  const [openNew, setOpenNew] = useState(false);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        overflow: 'auto',
        lineHeight: '120px',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        background: globalToken.colorPrimaryDarker,
      }}
    >
      <ModalCreateFolder
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
      />
      <ModalUploadFile
        openUploadModal={openUploadModal}
        setOpenUploadModal={setOpenUploadModal}
      />

      <Link to={ROUTES.HOME.path}>
        {!collapsed ? (
          <Image
            src={`/logoaAnimam.svg`}
            style={{ padding: '1.3rem' }}
            preview={false}
          />
        ) : (
          <Image
            src={`/logoaAnimamIkonoa.svg`}
            style={{ padding: '0.6rem' }}
            preview={false}
          />
        )}
      </Link>
      {loginToken && (
        <>
          <Popover
            trigger="click"
            placement="right"
            arrow={false}
            open={openNew}
            onOpenChange={() => setOpenNew(!openNew)}
            content={
              <>
                <Button onClick={() => setOpenCreateModal(true)} type="text">
                  <FolderAddOutlined rev="" />{' '}
                  <FormattedMessage
                    id="Create a new folder"
                    defaultMessage="Create a new folder"
                  />
                </Button>
                <br />
                <Button
                  onClick={() => {
                    setOpenUploadModal(true);
                    setOpenNew(false);
                  }}
                  type="text"
                >
                  <CloudUploadOutlined rev="" />{' '}
                  <FormattedMessage
                    id="Upload a new file"
                    defaultMessage="Upload a new file"
                  />
                </Button>
              </>
            }
          >
            <Button
              type="primary"
              shape="round"
              ghost
              style={{
                width: collapsed ? '70%' : '80%',
                margin: collapsed ? '15%' : '10%',
                lineHeight: globalToken.lineHeightLG,
                height: collapsed ? '2.8em' : '3em',
                fontSize: globalToken.fontSizeXL,
                backgroundColor: 'white',
              }}
            >
              <Space>
                <PlusOutlined rev="" />
                {!collapsed && (
                  <FormattedMessage id="New" defaultMessage="New" />
                )}
              </Space>
            </Button>
          </Popover>
          <Menu mode="inline" defaultSelectedKeys={[]} items={menuItems} />
        </>
      )}
    </Sider>
  );
};

export default Sidebar;
