import { Card } from 'antd';
import React from 'react';
import ListItem from '../ListItem/ListItem';

const StreamCard = ({ stream }) => {
  return (
    <Card title={stream.codec_long_name}>
      <ul>
        {stream.codec_name && (
          <li>
            <ListItem o_key={'codec_name'} data={stream.added} />
          </li>
        )}
        {stream.codec_type && (
          <li>
            <ListItem o_key={'codec_type'} data={stream.codec_type} />
          </li>
        )}
        {stream.sample_rate && (
          <li>
            <ListItem o_key={'sample_rate'} data={stream.sample_rate} />
          </li>
        )}
        {stream.channels && (
          <li>
            <ListItem o_key={'channels'} data={stream.channels} />
          </li>
        )}
        {stream.channel_layout && (
          <li>
            <ListItem o_key={'channel_layout'} data={stream.channel_layout} />
          </li>
        )}
        {stream.width && (
          <li>
            <ListItem o_key={'width'} data={stream.width} />
          </li>
        )}
        {stream.height && (
          <li>
            <ListItem o_key={'height'} data={stream.height} />
          </li>
        )}
        {stream.closed_captions !== undefined && (
          <li>
            <ListItem o_key={'closed_captions'} data={stream.closed_captions} />
          </li>
        )}
        {stream.r_frame_rate && (
          <li>
            <ListItem o_key={'r_frame_rate'} data={stream.r_frame_rate} />
          </li>
        )}
        {stream.max_bit_rate && (
          <li>
            <ListItem o_key={'max_bit_rate'} data={stream.max_bit_rate} />
          </li>
        )}
        {stream.bit_rate && (
          <li>
            <ListItem o_key={'bit_rate'} data={stream.bit_rate} />
          </li>
        )}
        {stream.bits_per_raw_sample && (
          <li>
            <ListItem
              o_key={'bits_per_raw_sample'}
              data={stream.bits_per_raw_sample}
            />
          </li>
        )}
        {stream.tags && (
          <li>
            <ListItem
              o_key={'tags'}
              data={
                <ul>
                  <li>
                    <ListItem o_key={'language'} data={stream.tags?.language} />
                  </li>
                  <li>
                    <ListItem
                      o_key={'handler_name'}
                      data={stream.tags?.handler_name}
                    />
                  </li>
                </ul>
              }
            />
          </li>
        )}
      </ul>
    </Card>
  );
};

export default StreamCard;
