import { GETFILE } from '../actions/getFile';

const initialState = {
  data: {},
  breadcrumb: [],
  loading: false,
  loaded: false,
  error: null,
};

const getFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GETFILE}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${GETFILE}_SUCCESS`:
      return {
        ...state,
        data: action.payload.data,
        breadcrumb: action.payload.data.breadcrumb ?? [
          { title: 'home' },
          { ...action.payload.data },
        ],
        loading: false,
        loaded: true,
      };
    case `${GETFILE}_ERROR`:
      return {
        ...state,
        data: {},
        breadcrumb: [],
        error: action.payload.message,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default getFileReducer;
