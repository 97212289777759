import React from 'react';
import { Button, Avatar, Dropdown } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { logout } from '../../store/actions/loginSession';

const LoginComponent = ({ navigate }) => {
  const loginState = useSelector((state) => state.login);
  const dispatch = useDispatch();
  return loginState.sessionData?.token ? (
    <Dropdown
      menu={{
        items: [
          {
            label: (
              <Button
                onClick={() => dispatch(logout(loginState.sessionData?.token))}
              >
                <FormattedMessage id="Log out" defaultMessage="Log out" />
              </Button>
            ),
            key: '1',
          },
        ],
      }}
      trigger={['click']}
    >
      <Avatar style={{ cursor: 'pointer' }} icon={<UserOutlined />} />
    </Dropdown>
  ) : (
    <Button onClick={() => navigate('/login')}>
      <FormattedMessage id="Log in" defaultMessage="Log in" />
    </Button>
  );
};
export default LoginComponent;
