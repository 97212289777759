import React, { useEffect, useState } from 'react';

const sec2Min = (sec) => {
  const min = Math.floor(sec / 60);
  const secRemain = Math.floor(sec % 60);
  return sec && !isNaN(sec)
    ? {
        min: min,
        sec: secRemain,
      }
    : { min: 0, sec: 0 };
};
export const useVideoStatus = (videoRef, timeUpdater = (s) => {}) => {
  // const videoRef = React.useRef(null);

  const [playerState, setPlayerState] = useState({
    currentTime: [0, 0],
    currentTimeSec: 0,
    duration: [0, 0],
    durationSec: 0,
    isPlaying: false,
  });
  const updateCurrent = (v) => {
    videoRef.current.currentTime = v;
    const { min, sec } = sec2Min(v);
    setPlayerState({
      ...playerState,
      currentTimeSec: v,
      currentTime: [min, sec],
    });
  };

  const updateDuration = (duration) => {
    const { min, sec } = sec2Min(duration);
    setPlayerState({
      ...playerState,
      durationSec: !isNaN(duration) ? duration : 0,
      duration: [min, sec],
    });
  };

  useEffect(() => {
    timeUpdater(playerState.currentTimeSec);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerState.currentTimeSec]);

  const intervalId = React.useRef(null);
  useEffect(() => {
    const { min, sec } = sec2Min(videoRef?.current?.duration);
    setPlayerState({
      ...playerState,
      durationSec: videoRef?.current?.duration,
      duration: [min, sec],
    });

    if (playerState.isPlaying) {
      intervalId.current = setInterval(() => {
        const videoCurrentTime = videoRef?.current?.currentTime;
        const { min, sec } = sec2Min(videoCurrentTime);
        setPlayerState({
          ...playerState,
          currentTimeSec: videoCurrentTime,
          currentTime: [min, sec],
        });
      }, 1000);
    }
    if (!playerState.isPlaying) {
      // @ts-ignore
      clearInterval(intervalId.current);
    }
    // @ts-ignore
    return () => clearInterval(intervalId.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerState.isPlaying]);

  return {
    functions: { setPlayerState, updateCurrent, updateDuration },
    data: {
      playerState,
      currentTime: playerState.currentTime,
      currentTimeSec: playerState.currentTimeSec,
      duration: playerState.duration,
      durationSec: playerState.durationSec,
      isPlaying: playerState.isPlaying,
    },
  };
};
