import React, { useState } from 'react';
import './videoStyles.css';
import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons';
import { Slider } from 'antd';

const AnimamVideo = ({ src, videoRef, data, functions }) => {
  const [showControls, setShowControls] = useState(true);
  const { playerState } = data;
  const { updateCurrent, updateDuration, setPlayerState } = functions;
  const handlePlay = () => {
    if (playerState.isPlaying) {
      videoRef.current.pause();
      setPlayerState({ ...playerState, isPlaying: false });
    } else {
      videoRef.current.play();
      setPlayerState({ ...playerState, isPlaying: true });
    }
  };
  return (
    <div className="videoContainer">
      <div
        className="playerContainer"
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() =>
          setTimeout(() => {
            setShowControls(false);
          }, 2000)
        }
        role="button"
        tabIndex={0}
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption*/}
        <video
          className="videoPlayer"
          ref={videoRef}
          src={src}
          onClick={handlePlay}
          onKeyDown={handlePlay}
          onDurationChange={(e) => {
            updateDuration(e.target.duration);
          }}
        ></video>
        {(showControls || !playerState.isPlaying) && (
          <div className="controlsContainer">
            <div className="controls">
              {playerState.isPlaying ? (
                <button className="controlButton" onClick={handlePlay}>
                  <PauseCircleFilled
                    rev=""
                    style={{ fontSize: '2rem', color: 'white' }}
                  />
                </button>
              ) : (
                <button className="controlButton" onClick={handlePlay}>
                  <PlayCircleFilled
                    style={{ fontSize: '2rem', color: 'white' }}
                  />
                </button>
              )}

              <div className="duration">
                {`0${playerState.currentTime[0]}`.slice(-2)}:
                {`0${playerState.currentTime[1]}`.slice(-2)} /{' '}
                {`0${playerState.duration[0]}`.slice(-2)}:
                {`0${playerState.duration[1]}`.slice(-2)}
              </div>
            </div>
            <div className="timeline">
              <Slider
                max={playerState.durationSec}
                value={playerState.currentTimeSec}
                tooltip={{ open: false }}
                onChange={(v) => {
                  updateCurrent(v);
                }}
                defaultValue={0}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnimamVideo;
