import React from 'react';

import { Card, Col, Dropdown, Row, theme } from 'antd';

import { FolderFilled, MoreOutlined } from '@ant-design/icons';
const FolderCard = ({
  uuid,
  title,
  type,
  img,
  isSelected,
  handleSelected,
  dropdownItems,
}) => {
  const { token: globalToken } = theme.useToken();
  return (
    <Card
      hoverable
      className="prevent-select"
      style={{
        backgroundColor: isSelected ? globalToken.colorPrimaryBgHover : '',
        color: isSelected ? globalToken.colorPrimaryTextActive : '',
      }}
      onClick={(e) => handleSelected(e, { uuid, type })}
    >
      <Row align="middle" justify="space-between">
        <Col span={4}>
          {!img && (
            <FolderFilled
              rev=""
              style={{ fontSize: '1.8em', marginRight: globalToken.marginSM }}
            />
          )}
        </Col>
        <Col
          span={16}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '1.3em',
          }}
        >
          {title}
        </Col>
        <Col span={4}>
          <Dropdown menu={{ items: dropdownItems }} trigger={['click']}>
            <MoreOutlined
              rev=""
              style={{ fontSize: '1.8em', marginLeft: globalToken.marginSM }}
            />
          </Dropdown>
        </Col>
      </Row>
    </Card>
  );
};
export default FolderCard;
