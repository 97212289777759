import React from 'react';

import { Card, Image, Row, theme } from 'antd';
import { CONTENT_TYPE_ICONS } from '../../config/constants';

const FileCard = ({
  uuid,
  title,
  type,
  photo,
  thumbnail_url,
  isSelected,
  handleSelected,
  disabled,
}) => {
  const { token: globalToken } = theme.useToken();
  return (
    <Card
      hoverable={!disabled}
      title={title}
      size="small"
      className="prevent-select"
      style={{
        backgroundColor: isSelected ? globalToken.colorPrimaryBgHover : '',
      }}
      onClick={(e) => {
        if (!disabled) {
          handleSelected(e, { uuid, type });
        }
      }}
    >
      {photo ? (
        <Image width={'100%'} preview={false} alt={title} src={photo} />
      ) : thumbnail_url ? (
        <div
          style={{ position: 'relative', textAlign: 'center', color: 'white' }}
        >
          <Image
            width={'100%'}
            preview={false}
            alt={title}
            src={`${process.env.REACT_APP_BACKEND_PATH}${thumbnail_url}`}
          />
          <span
            style={{
              backgroundColor: 'black',
              padding: '5px',
              borderRadius: '5px',
              position: 'absolute',
              bottom: '0px',
              left: '0px',
            }}
          >
            {Object.keys(CONTENT_TYPE_ICONS).includes(type)
              ? CONTENT_TYPE_ICONS[type]('1.5rem')
              : CONTENT_TYPE_ICONS['else']('1.5rem')}
          </span>
        </div>
      ) : (
        <Row justify="center" align="top">
          {Object.keys(CONTENT_TYPE_ICONS).includes(type)
            ? CONTENT_TYPE_ICONS[type]('3.5rem')
            : CONTENT_TYPE_ICONS['else']('3.5rem')}
        </Row>
      )}
    </Card>
  );
};
export default FileCard;
