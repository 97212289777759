import { apiHeaders, medias } from '../../api';
import { API_URLS } from '../../config';
import { auth } from '../utils';
export const GETFILE = 'GETFILE';
export const getFile = (access_token, id) => {
  return async (dispatch) => {
    dispatch({
      type: `${GETFILE}_PENDING`,
    });
    try {
      const path = `${API_URLS.FILE}${id}/`;
      const fileData = (await apiHeaders(auth(access_token)).get(path)) || null;

      dispatch({
        type: `${GETFILE}_SUCCESS`,
        payload: fileData.data,
      });
    } catch (error) {
      dispatch({
        type: `${GETFILE}_ERROR`,
        payload: error,
      });
    }
  };
};

export const GETFILEMETADATA = 'GETFILEMETADATA';
export const getFileMetadata = (access_token, id) => {
  return async (dispatch) => {
    dispatch({
      type: `${GETFILEMETADATA}_PENDING`,
    });
    try {
      const path = API_URLS.METADATA.replace('{{fileid}}', id);
      const fileMetadataData =
        (await apiHeaders(auth(access_token)).get(path)) || null;
      dispatch({
        type: `${GETFILEMETADATA}_SUCCESS`,
        payload: fileMetadataData.data,
      });
    } catch (error) {
      dispatch({
        type: `${GETFILEMETADATA}_ERROR`,
        payload: error,
      });
    }
  };
};

export const GETFILESRT = 'GETFILESRT';
export const getFileSrt = (path) => {
  return async (dispatch) => {
    dispatch({
      type: `${GETFILESRT}_PENDING`,
    });
    try {
      if (path !== 'null') {
        const fileMetadataData = (await medias.get(path)) || null;
        dispatch({
          type: `${GETFILESRT}_SUCCESS`,
          payload: fileMetadataData.data,
        });
      } else {
        dispatch({
          type: `${GETFILESRT}_SUCCESS`,
          payload: '',
        });
      }
    } catch (error) {
      dispatch({
        type: `${GETFILESRT}_ERROR`,
        payload: error,
      });
    }
  };
};
