import React from 'react';
import { FormattedMessage } from 'react-intl';

export const tourDef = (cancelRef, originRef, destinationRef, moveRef) => [
  {
    title: <FormattedMessage id="Moving items" defaultMessage="Moving items" />,
    description: (
      <FormattedMessage
        id="Here are listed the items you are going to move."
        defaultMessage="Here are listed the items you are going to move."
      />
    ),
    target: () => originRef.current,
  },
  {
    title: (
      <FormattedMessage
        id="Resultant breadcrumb"
        defaultMessage="Resultant breadcrumb"
      />
    ),
    description: (
      <FormattedMessage
        id="This will be the resultant breadcrumb you will have after moving the item. You can modify the destination navigating as usual."
        defaultMessage="This will be the resultant breadcrumb you will have after moving the item. You can modify the destination navigating as usual."
      />
    ),
    target: () => destinationRef.current,
  },
  {
    title: <FormattedMessage id="Cancel" defaultMessage="Cancel" />,
    description: (
      <FormattedMessage
        id="Cancel the move action by clicking the X icon."
        defaultMessage={'Cancel the move action by clicking the X icon.'}
      />
    ),
    target: () => cancelRef.current,
  },
  {
    title: <FormattedMessage id="Move" defaultMessage="Move the items" />,
    description: (
      <FormattedMessage
        id="Click here to definitely move the selected items to the current folder"
        defaultMessage={
          'Click here to definitely move the selected items to the current folder'
        }
      />
    ),
    target: () => moveRef.current,
  },
];
