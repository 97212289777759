import { REQUESTPASSWORDRESET, PASSWORDRESET } from '../actions/users';
import { getRequestKey } from '../utils';
const initialState = {
  requestpasswordreset: {
    loading: false,
    loaded: false,
    error: null,
  },
  passwordreset: {
    loading: false,
    loaded: false,
    error: null,
  },
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${REQUESTPASSWORDRESET}_PENDING`:
    case `${PASSWORDRESET}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${REQUESTPASSWORDRESET}_SUCCESS`:
    case `${PASSWORDRESET}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${REQUESTPASSWORDRESET}_ERROR`:
    case `${PASSWORDRESET}_ERROR`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
export default usersReducer;
