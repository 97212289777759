import React, { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CloudUploadOutlined, CloseOutlined } from '@ant-design/icons';

import { uploadFile } from '../../store/actions';

import { Modal, Tag, message, Upload, Form, Radio } from 'antd';

const { Dragger } = Upload;

const ModalUploadFile = ({ openUploadModal, setOpenUploadModal }) => {
  const messages = defineMessages({
    sidebar_uploading: {
      id: 'sidebar.uploading',
      defaultMessage: 'Select a file to upload it into {destination}',
    },
    upload_success: {
      id: 'upload_success',
      defaultMessage: '{filename} file uploaded successfully!',
    },
    upload_failed: {
      id: 'upload_failed',
      defaultMessage: '{filename} file uploaded failed...',
    },
  });
  const dispatch = useDispatch();
  const { folderid } = useParams();

  const contentsState = useSelector((state) => state.contents);
  const loginToken = useSelector((state) => state.login.sessionData.token);

  const [fileLanguage, setFileLanguage] = useState('');

  const draggerProps = {
    name: 'file',
    multiple: true,
    disabled: !fileLanguage,
    customRequest: async (options) => {
      const { onSuccess, onError, file } = options;
      dispatch(
        uploadFile(
          loginToken,
          file.name,
          folderid ? folderid : null,
          file,
          fileLanguage,
          {
            onSuccess: onSuccess,
            onError: onError,
          },
        ),
      );
      setFileLanguage(false);
    },
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: (file) => <CloseOutlined rev="" />,
    },
    onChange(info) {
      const { status } = info.file;
      // if (status !== 'uploading') {
      //   console.log(info.file);
      // }
      if (status === 'done') {
        message.success(
          <FormattedMessage
            {...messages.upload_success}
            values={{
              filename: info.file.name,
            }}
          />,
        );
      } else if (status === 'error') {
        message.error(
          <FormattedMessage
            {...messages.upload_failed}
            values={{
              filename: info.file.name,
            }}
          />,
        );
      }
    },
    // onDrop(e) {
    //   console.log('Dropped files', e.dataTransfer.files);
    // },
  };
  return (
    <Modal
      title={
        <FormattedMessage
          id="Upload a new file"
          defaultMessage="Upload a new file"
        />
      }
      centered
      open={openUploadModal}
      // onOk={() => {
      //   makeCreateEffective();
      // }}
      // confirmLoading={uploadFileState.loading}
      onCancel={() => {
        setOpenUploadModal(false);
      }}
      // afterOpenChange={(open) => open && createInputRef.current?.focus()}
      // @ts-ignore
      footer={() => <></>}
    >
      <p>
        <FormattedMessage
          {...messages.sidebar_uploading}
          values={{
            destination: (
              <Tag>
                {contentsState.breadcrumb.slice(-1).length > 0 ? (
                  contentsState.breadcrumb.slice(-1)[0].title
                ) : (
                  <FormattedMessage id="Home" defaultMessage="Home" />
                )}
              </Tag>
            ),
          }}
        />
      </p>
      <Form>
        <Form.Item
          name="radio-language"
          label={
            <strong>
              <FormattedMessage
                id="File language"
                defaultMessage="File language"
              />
            </strong>
          }
        >
          <Radio.Group
            options={[
              { label: 'EU', value: 'eu' },
              { label: 'ES', value: 'es' },
              { label: 'EN', value: 'en' },
            ]}
            value={fileLanguage}
            onChange={({ target: { value } }) => setFileLanguage(value)}
            optionType="button"
            buttonStyle="solid"
          ></Radio.Group>
        </Form.Item>

        <Form.Item
          name="radio-language"
          label={
            <strong>
              <FormattedMessage id="File" defaultMessage="File" />
            </strong>
          }
        >
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined rev="" />
            </p>
            <p className="ant-upload-text">
              <FormattedMessage
                id="Click or drag file to this area to upload"
                defaultMessage="Click or drag file to this area to upload"
              />
            </p>
          </Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalUploadFile;
