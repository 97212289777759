import { GETFILESRT } from '../actions/getFile';

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  error: null,
};

const getFileSrtReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GETFILESRT}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${GETFILESRT}_SUCCESS`:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    case `${GETFILESRT}_ERROR`:
      return {
        ...state,
        data: {},
        error: action.payload.message,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default getFileSrtReducer;
