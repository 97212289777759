import React from 'react';
import { Flex, Progress } from 'antd';
import { FormattedMessage } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';

const getAdituTaskTab = (fileState, selectedTab) => {
  const key = 'aditu-task';
  const task = fileState?.data?.aditu_task_status;
  const status = task?.error ? { status: 'exception' } : {};
  return fileState.loaded && (task?.percentage || task?.finished)
    ? [
        {
          label: (
            <>
              {!task?.finished && task?.percentage && <LoadingOutlined />}{' '}
              <FormattedMessage id="Aditu task" defaultMessage={'Aditu task'} />
            </>
          ),
          key: key,
          children: task && selectedTab === key && (
            <Flex vertical={true} align="center">
              {fileState.loaded && task && (
                <>
                  <Progress
                    type="circle"
                    percent={task?.finished ? 100 : task?.percentage}
                    {...status}
                    format={(p) =>
                      task?.finished ? (
                        <FormattedMessage id="Done!" defaultMessage={'Done!'} />
                      ) : (
                        `${p}%`
                      )
                    }
                  />
                  {task?.message && (
                    <>
                      <strong>
                        <FormattedMessage
                          id="Task message:"
                          defaultMessage={'Task message:'}
                        />
                      </strong>
                      {task?.message}s
                    </>
                  )}
                  {task?.execution_time && (
                    <>
                      <strong>
                        <FormattedMessage
                          id="Execution time:"
                          defaultMessage={'Execution time:'}
                        />
                      </strong>
                      {task?.execution_time.toFixed(2)}s
                    </>
                  )}
                </>
              )}
            </Flex>
          ),
        },
      ]
    : [];
};

export default getAdituTaskTab;
