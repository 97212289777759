import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';

const { Title } = Typography;
const Settings = () => {
  return (
    <div>
      <Title>
        <FormattedMessage id="Settings" defaultMessage="Settings" />
      </Title>
      <p>PROBA</p>
    </div>
  );
};

export default Settings;
