import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import App from './App';
import { ROUTES } from './config';

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={'/home'} />}></Route>
      <Route path="/" element={<App />}>
        <Route index element={<ROUTES.HOME.Component />} />
        {Object.values(ROUTES).map((route, key) => {
          return (
            <Route key={key} path={route.path} element={<route.Component />} />
          );
        })}
      </Route>
    </Routes>
  );
};
export default AppRoutes;
