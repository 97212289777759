import { Card, Flex, List } from 'antd';
import AnimamVideo from '../AnimamVideo/AnimamVideo';
import React, { useEffect, useRef, useState } from 'react';
import { getCurrentSrtId, getCurrentTextStyle } from '../AnimamVideo/utils';
import { useVideoStatus } from '../AnimamVideo/useVideoStatus';
import { FormattedMessage } from 'react-intl';

export const TranscriptionTabComponent = ({
  fileMetadataState,
  fileState,
  srtArray,
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const currentSrtId = getCurrentSrtId(srtArray, currentTime);
  const ref = useRef();
  const videoRef = React.useRef(null);

  const { data, functions } = useVideoStatus(videoRef, setCurrentTime);
  const { updateCurrent } = functions;
  useEffect(() => {
    if (currentSrtId !== -1 && ref.current) {
      ref.current?.scrollIntoView({
        top: 0,
        behavior: 'smooth',
      });
    }
    return () => {};
  }, [currentSrtId]);
  return (
    <>
      <Flex justify="center">
        {fileMetadataState.metadata?.format?.filename && (
          <AnimamVideo
            videoRef={videoRef}
            data={data}
            functions={functions}
            src={`${
              process.env.REACT_APP_BACKEND_PATH
            }${fileMetadataState.metadata?.format?.filename.replace(
              '/home/csmant/django',
              '',
            )}`}
          />
        )}
        <div
          style={{
            width: '100%',
          }}
        >
          {srtArray.length > 0 && (
            <div className="scrollableListContainer">
              <List
                itemLayout="vertical"
                size="large"
                dataSource={srtArray}
                renderItem={(item) => {
                  return (
                    <List.Item
                      key={item?.id}
                      ref={item.id === currentSrtId ? ref : null}
                      style={{ ...getCurrentTextStyle(item, currentTime) }}
                    >
                      <div>
                        <strong>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                          <a
                            onClick={() => {
                              // @ts-ignore
                              updateCurrent(item.startSeconds);
                            }}
                          >
                            [{item.startTime} - {item.endTime}]
                          </a>
                        </strong>
                      </div>
                      {item?.text}
                    </List.Item>
                  );
                }}
              ></List>
            </div>
          )}
        </div>
      </Flex>
      {fileState.data?.transkripzioa && (
        <Card>
          <h3>
            <FormattedMessage
              id="Transcription:"
              defaultMessage="Transcription:"
            />
          </h3>
          <p>{fileState.data?.transkripzioa}</p>
        </Card>
      )}
    </>
  );
};
