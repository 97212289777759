import { apiHeaders } from '../../api';
import { API_URLS } from '../../config';
import { auth } from '../utils';
export const CONTENTS = 'CONTENTS';
export const getContents = (access_token, id = '') => {
  return async (dispatch) => {
    dispatch({
      type: `${CONTENTS}_PENDING`,
    });
    try {
      const path = id ? `${API_URLS.FOLDERS}${id}/` : `${API_URLS.FOLDERS}`;
      const contentsData =
        (await apiHeaders(auth(access_token)).get(path)) || null;
      dispatch({
        type: `${CONTENTS}_SUCCESS`,
        payload: contentsData.data,
      });
    } catch (error) {
      dispatch({
        type: `${CONTENTS}_ERROR`,
        payload: error,
      });
    }
  };
};
