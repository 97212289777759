import api, { apiHeaders } from '../../api';
import { API_URLS } from '../../config';
import { auth } from '../utils';
export const LOGIN = 'LOGIN';
export const login = (user, pass) => {
  return async (dispatch) => {
    dispatch({
      type: `${LOGIN}_PENDING`,
    });
    try {
      const userData =
        (await api.post(API_URLS.LOGIN, {
          username: user,
          password: pass,
        })) || null;
      dispatch({
        type: `${LOGIN}_SUCCESS`,
        payload: userData,
      });
    } catch (error) {
      dispatch({
        type: `${LOGIN}_ERROR`,
        payload: error,
      });
    }
  };
};

export const LOGIN_RENEW = 'LOGIN_RENEW';

export const login_renew = (access_token) => {
  return async (dispatch) => {
    dispatch({
      type: `${LOGIN_RENEW}_PENDING`,
    });
    try {
      const userData =
        (await apiHeaders(auth(access_token)).get(API_URLS.RENEW_LOGIN)) ||
        null;
      dispatch({
        type: `${LOGIN_RENEW}_SUCCESS`,
        payload: userData.data,
      });
    } catch (error) {
      dispatch({
        type: `${LOGIN_RENEW}_ERROR`,
        payload: error,
      });
    }
  };
};
export const LOGOUT = 'LOGOUT';
export const logout = (access_token) => {
  return async (dispatch) => {
    dispatch({
      type: `${LOGOUT}_PENDING`,
    });
    try {
      const response =
        (await apiHeaders(auth(access_token)).post(API_URLS.LOGOUT)) || null;
      dispatch({
        type: `${LOGOUT}_SUCCESS`,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: `${LOGOUT}_ERROR`,
        payload: error,
      });
    }
  };
};
