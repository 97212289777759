import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { login } from '../../store/actions/loginSession';

import { Button, Spin, Form, Input, Row, Col, Result, theme } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../config/routes';
const LoginForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const { token: globalToken } = theme.useToken();

  useEffect(() => {
    loginState.loaded &&
      loginState.sessionData.token &&
      navigate(`/${ROUTES.HOME.path}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState]);
  const messages = defineMessages({
    required_username: {
      id: 'login.required_username',
      defaultMessage: 'Please input your Username!',
    },
    required_password: {
      id: 'login.required_password',
      defaultMessage: 'Please input your Password!',
    },
    username: {
      id: 'login.username',
      defaultMessage: 'Username',
    },
    password: {
      id: 'login.password',
      defaultMessage: 'Password',
    },
  });
  const onFinish = (values) => {
    // @ts-ignore
    dispatch(login(values.username, values.password));
  };
  return (
    <Row gutter={16}>
      {loginState.error ? (
        <Col span={24}>
          <Result status="error" title={loginState.error.message} />
        </Col>
      ) : (
        <Col span={24}>
          <Result
            icon={
              <UserOutlined
                rev=""
                style={{ color: globalToken.colorPrimary }}
              />
            }
            title={<FormattedMessage id="Welcome" defaultMessage="Welcome" />}
          />
        </Col>
      )}

      <Col span={6} offset={9}>
        <Spin spinning={loginState.loading}>
          <Form className="login-form" onFinish={onFinish}>
            <Form.Item
              name="username"
              validateStatus={loginState.error ? 'error' : ''}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.required_username),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" rev="" />}
                placeholder={intl.formatMessage(messages.username)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              validateStatus={loginState.error ? 'error' : ''}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.required_password),
                },
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" rev="" />}
                type="password"
                placeholder={intl.formatMessage(messages.password)}
              />
            </Form.Item>
            <Form.Item>
              <Link
                className="login-form-forgot"
                to={`/${ROUTES.PASSWORDRESET.path}`}
              >
                <FormattedMessage
                  id="Forgot password"
                  defaultMessage="Forgot password"
                />
              </Link>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                <FormattedMessage id="Log in" defaultMessage="Log in" />
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
};

export default LoginForm;
