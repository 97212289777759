import { apiHeaders } from '../../api';
import { API_URLS } from '../../config';
import { auth } from '../utils';
export const EDITFOLDER = 'EDITFOLDER';
export const editFolder = (access_token, origin, new_data) => {
  return async (dispatch) => {
    dispatch({
      type: `${EDITFOLDER}_PENDING`,
    });
    try {
      const editData =
        (await apiHeaders(auth(access_token)).put(
          `${API_URLS.FOLDERS}${origin}/`,
          new_data,
        )) || null;
      dispatch({
        type: `${EDITFOLDER}_SUCCESS`,
        payload: { ...editData.data, moved: new_data.parent ? true : false },
      });
    } catch (error) {
      dispatch({
        type: `${EDITFOLDER}_ERROR`,
        payload: error,
      });
    }
  };
};
