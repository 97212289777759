import React from 'react';
import ListItem from '../ListItem/ListItem';
import { Flex, Image } from 'antd';
import { FormattedMessage } from 'react-intl';

const getMetadataTab = (fileMetadataState, fileState, selectedTab) => {
  const key = 'metadata';
  const preview = fileMetadataState.metadata?.format?.filename
    ? {
        preview: {
          imageRender: () => (
            <video
              muted
              controls
              width={'100%'}
              height={'100%'}
              src={`${
                process.env.REACT_APP_BACKEND_PATH
              }${fileMetadataState.metadata?.format?.filename.replace(
                '/home/csmant/django',
                '',
              )}`}
            />
          ),
          toolbarRender: () => null,
        },
      }
    : {};
  return fileState.loaded
    ? [
        {
          label: 'Metadata',
          key: key,
          children: selectedTab === key && (
            <Flex style={{ width: '100%' }}>
              {fileState.data?.thumbnail_url && (
                <Image
                  width={'30%'}
                  height={'100%'}
                  {...preview}
                  src={`${process.env.REACT_APP_BACKEND_PATH}${fileState.data?.thumbnail_url}`}
                />
              )}
              <div style={{ paddingLeft: '3rem' }}>
                <strong>
                  <FormattedMessage id="data" defaultMessage={'Data:'} />
                </strong>
                <ul>
                  {fileState.data.title && (
                    <li>
                      <ListItem o_key={'title'} data={fileState.data.title} />
                    </li>
                  )}
                  {fileState.data.added && (
                    <li>
                      <ListItem
                        o_key={'added'}
                        data={fileState.data.added}
                        format="datetime"
                      />
                    </li>
                  )}
                  {fileState.data.modified && (
                    <li>
                      <ListItem
                        o_key={'modified'}
                        data={fileState.data.modified}
                        format="datetime"
                      />
                    </li>
                  )}
                  {fileState.data.duration && (
                    <li>
                      <ListItem
                        o_key={'duration'}
                        data={fileState.data.duration}
                      />
                    </li>
                  )}
                  {fileState.data.file_type && (
                    <li>
                      <ListItem
                        o_key={'file_type'}
                        data={fileState.data.file_type}
                      />
                    </li>
                  )}
                </ul>
                {fileMetadataState.loaded && fileMetadataState.metadata && (
                  <>
                    <strong>
                      <FormattedMessage
                        id="metadata"
                        defaultMessage={'Metadata:'}
                      />
                    </strong>
                    <ul>
                      <li>
                        <ListItem
                          o_key={'size'}
                          data={fileMetadataState.metadata?.size}
                          format="filesize"
                        />
                      </li>
                      <li>
                        <ListItem
                          o_key={'created'}
                          data={fileMetadataState.metadata?.created}
                          format="datetime"
                        />
                      </li>
                      <li>
                        <ListItem
                          o_key={'modified'}
                          data={fileMetadataState.metadata?.modified}
                          format="datetime"
                        />
                      </li>
                      {fileMetadataState.metadata?.format?.filename && (
                        <li>
                          <ListItem
                            o_key={'filename'}
                            data={fileMetadataState.metadata?.format?.filename}
                          />
                        </li>
                      )}
                      {fileMetadataState.metadata?.format?.nb_streams && (
                        <li>
                          <ListItem
                            o_key={'nb_streams'}
                            data={
                              fileMetadataState.metadata?.format?.nb_streams
                            }
                          />
                        </li>
                      )}
                      {fileMetadataState.metadata?.format?.format_name && (
                        <li>
                          <ListItem
                            o_key={'format_name'}
                            data={
                              fileMetadataState.metadata?.format?.format_name
                            }
                          />
                        </li>
                      )}
                      {fileMetadataState.metadata?.format?.start_time && (
                        <li>
                          <ListItem
                            o_key={'start_time'}
                            data={
                              fileMetadataState.metadata?.format?.start_time
                            }
                          />
                        </li>
                      )}
                      {fileMetadataState.metadata?.format?.format_long_name && (
                        <li>
                          <ListItem
                            o_key={'format_long_name'}
                            data={
                              fileMetadataState.metadata?.format
                                ?.format_long_name
                            }
                          />
                        </li>
                      )}
                      {fileMetadataState.metadata?.format?.duration && (
                        <li>
                          <ListItem
                            o_key={'duration'}
                            data={fileMetadataState.metadata?.format?.duration}
                          />
                        </li>
                      )}
                      {fileMetadataState.metadata?.format?.bit_rate && (
                        <li>
                          <ListItem
                            o_key={'bit_rate'}
                            data={fileMetadataState.metadata?.format?.bit_rate}
                          />
                        </li>
                      )}
                    </ul>
                  </>
                )}
                {/* {Object.entries(fileState.data).map((obj, key) => (
                      <li key={key}>
                        <strong>{obj[0]}:</strong> {JSON.stringify(obj[1])}
                      </li>
                    ))} */}
              </div>
            </Flex>
          ),
        },
      ]
    : [];
};
export default getMetadataTab;
