import { CONTENTS } from '../actions/contents';

const initialState = {
  items: [],
  breadcrumb: [],
  loading: false,
  loaded: false,
  error: null,
};

const contentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CONTENTS}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${CONTENTS}_SUCCESS`:
      return {
        ...state,
        items: [
          ...action.payload.data.folders,
          ...action.payload.data.contents,
        ],
        breadcrumb: action.payload.data.breadcrumb ?? [],
        loading: false,
        loaded: true,
      };
    case `${CONTENTS}_ERROR`:
      return {
        ...state,
        items: [],
        breadcrumb: [],
        error: action.payload.message,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default contentsReducer;
