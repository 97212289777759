import { Button, Space, Row, Col, theme, Tag, Tour } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  DoubleRightOutlined,
  EditOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import Cookies from 'js-cookie';
import { MV_COOKIE, MV_COOKIE_DATA } from '../../config';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb } from '..';
import { useParams } from 'react-router-dom';
import { messages } from './messages';
import { tourDef } from './tour';
import { handlerMoving, handleMove } from '../../helpers/contentActions';
const ActionsComponent = ({
  rowSelection,
  moving,
  setMoving,
  apiMessage,
  renaming,
  deleting,
}) => {
  const dispatch = useDispatch();
  const { token: globalToken } = theme.useToken();
  const { folderid } = useParams();
  const cancelRef = useRef(null);
  const originRef = useRef(null);
  const destinationRef = useRef(null);
  const moveRef = useRef(null);

  const [tour, setTour] = useState(false);

  const loginToken = useSelector((state) => state.login.sessionData.token);
  const contentsState = useSelector((state) => state.contents);

  const { setRenameModal, setRenameItem } = renaming;
  const { setOpenDeleteModal, setDeleteItems } = deleting;
  const disabledRename = rowSelection.selectedRowKeys.length > 1;
  const hasSelected = rowSelection.selectedRowKeys.length < 1;
  const tourSteps = tourDef(cancelRef, originRef, destinationRef, moveRef);

  const handleRename = () => {
    setRenameItem({
      ...contentsState.items.find(
        (i) => i.uuid === rowSelection.selectedRowKeys[0],
      ),
    });
    setRenameModal(true);
  };

  const keysFunction = useCallback((event) => {
    if (event.key === 'F2') {
      if (!disabledRename) {
        handleRename();
      }
    }
    if (event.key === 'Escape') {
      rowSelection.onChange([]);
      setMoving([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    document.addEventListener('keydown', keysFunction, false);
    return () => {
      document.removeEventListener('keydown', keysFunction, false);
    };
  }, [keysFunction]);

  return (
    <>
      {!moving.length && rowSelection.selectedRowKeys.length ? (
        <Row
          style={{
            borderRadius: globalToken.borderRadiusLG,
            marginBottom: globalToken.marginSM,
            paddingTop: globalToken.paddingContentVerticalSM,
            paddingBottom: globalToken.paddingContentVerticalSM,
            backgroundColor: globalToken.colorPrimaryBgHover,
          }}
        >
          <Col offset={1} span={23}>
            <span
              style={{
                fontSize: '1.2em',
                marginRight: globalToken.marginMD,
              }}
            >
              <Button size="large" type="link">
                <CloseOutlined
                  rev=""
                  onClick={() => rowSelection.onChange([])}
                />
              </Button>
              <FormattedMessage
                {...messages.selected_items}
                values={{ count: rowSelection.selectedRowKeys.length }}
              />
            </span>

            {!hasSelected && (
              <Space.Compact>
                <Button
                  disabled={disabledRename}
                  icon={<EditOutlined rev="" />}
                  onClick={handleRename}
                >
                  <Space>
                    <FormattedMessage id="Rename" defaultMessage="Rename" />
                  </Space>
                </Button>
                <Button
                  onClick={() => {
                    handlerMoving(
                      rowSelection.selectedRowKeys,
                      rowSelection.selectedRowKeys.map((s, key) => {
                        return {
                          title: contentsState.items.find((i) => i.uuid === s)
                            .title,
                          type: contentsState.items.find((i) => i.uuid === s)
                            .type,
                        };
                      }),
                      setMoving,
                    );
                  }}
                  icon={<DoubleRightOutlined rev="" />}
                >
                  <Space>
                    <FormattedMessage id="Move" defaultMessage="Move" />
                  </Space>
                </Button>
                <Button
                  icon={<DeleteOutlined rev="" />}
                  onClick={() => {
                    setDeleteItems(
                      rowSelection.selectedRowKeys.map((s, key) => {
                        return {
                          uuid: contentsState.items.find((i) => i.uuid === s)
                            .uuid,
                          title: contentsState.items.find((i) => i.uuid === s)
                            .title,
                          type: contentsState.items.find((i) => i.uuid === s)
                            .type,
                        };
                      }),
                    );
                    setOpenDeleteModal(true);
                  }}
                  danger
                >
                  <Space>
                    <FormattedMessage id="Delete" defaultMessage="Delete" />
                  </Space>
                </Button>
              </Space.Compact>
            )}
          </Col>
        </Row>
      ) : (
        moving.length > 0 && (
          <>
            <Row
              style={{
                borderRadius: globalToken.borderRadiusLG,
                marginBottom: globalToken.marginSM,
                paddingTop: globalToken.paddingContentVerticalSM,
                paddingBottom: globalToken.paddingContentVerticalSM,
                backgroundColor: globalToken.colorPrimaryBgHover,
              }}
            >
              <Col span={24}>
                <Button size="large" onClick={() => setTour(true)} type="link">
                  <InfoCircleOutlined rev="" style={{ fontSize: '1.5em' }} />
                </Button>
              </Col>
              <Col offset={3} span={21}>
                <FormattedMessage
                  id="You are moving an item. The following breadcrumb will be the resultant structure."
                  defaultMessage="You are moving an item. The following breadcrumb will be the resultant structure."
                />
                <div ref={destinationRef}>
                  <Breadcrumb
                    extras={[
                      {
                        title: (
                          <span ref={originRef}>
                            {Cookies.get(MV_COOKIE_DATA) &&
                              JSON.parse(Cookies.get(MV_COOKIE_DATA)).map(
                                (t, key) => (
                                  <Tag
                                    key={key}
                                    style={{
                                      fontSize: '1em',
                                      lineHeight: '1.3em',
                                    }}
                                  >
                                    {t.title}
                                  </Tag>
                                ),
                              )}
                          </span>
                        ),
                      },
                    ]}
                  />
                </div>
              </Col>
              <Col offset={19} span={5}>
                <Space>
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      Cookies.remove(MV_COOKIE);
                      Cookies.remove(MV_COOKIE_DATA);
                      setMoving([]);
                    }}
                    ref={cancelRef}
                  >
                    <CloseOutlined rev="" />
                    <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                  </Button>
                  <Button
                    type="primary"
                    onClick={() =>
                      handleMove({
                        loginToken,
                        folderid,
                        dispatch,
                        setMoving,
                      })
                    }
                    ref={moveRef}
                  >
                    <FormattedMessage id="Move" defaultMessage="Move" />
                  </Button>
                </Space>
              </Col>
            </Row>
            <Tour
              open={tour}
              onClose={() => setTour(false)}
              steps={tourSteps}
            />
          </>
        )
      )}
    </>
  );
};
export default ActionsComponent;
