import { DELETEFOLDER } from '../actions/deleteFolder';

const initialState = {
  data: [],
  message: '',
  loading: false,
  loaded: false,
  error: null,
};

const deleteFolderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${DELETEFOLDER}_PENDING`:
      return {
        ...state,
        message: '',
        loading: true,
        loaded: false,
        error: null,
      };
    case `${DELETEFOLDER}_SUCCESS`:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        loading: false,
        loaded: true,
      };
    case `${DELETEFOLDER}_ERROR`:
      return {
        ...state,
        data: {},
        error: action.payload.message,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default deleteFolderReducer;
