import React from 'react';

import { SettingOutlined, FilterOutlined } from '@ant-design/icons';

import { LoginComponent, LanguageSelector } from '../';
import { ROUTES } from '../../config';
import { useSelector } from 'react-redux';

import { Layout, Row, Col, Input, Button, Popover, theme } from 'antd';
import { defineMessages, useIntl } from 'react-intl';

const { Header } = Layout;
const Navbar = (props) => {
  const { token: globalToken } = theme.useToken();
  const intl = useIntl();
  const loginToken = useSelector((state) => state.login.sessionData.token);
  const messages = defineMessages({
    search_here: {
      id: 'navbar.search_here',
      defaultMessage: 'Search here...',
    },
  });
  const selectAfter = (
    <Popover
      placement="bottomLeft"
      content={<p>Atal honetan iragazkien aukerak bistaratuko dira</p>}
      title="Filter settings"
      trigger="click"
    >
      <Button type="ghost">
        <FilterOutlined rev="" />
      </Button>
    </Popover>
  );
  return (
    <Header
      style={{
        background: globalToken.colorPrimaryLight,
        paddingLeft: '20px',
        paddingTop: '5px',
        height: 'auto',
      }}
    >
      <Row justify="center" gutter={[30, 0]}>
        <Col
          xs={{ span: 24, order: 1 }}
          lg={{ span: 17, order: 0 }}
          xl={{ span: 18, order: 0 }}
          xxl={{ span: 18, order: 0 }}
        >
          {loginToken && (
            <Input.Search
              size="large"
              placeholder={intl.formatMessage(messages.search_here)}
              style={{ paddingTop: globalToken.paddingContentVerticalSM }}
              addonBefore={selectAfter}
            />
          )}
        </Col>
        <Col
          xs={{ span: 24, order: 0 }}
          sm={{ span: 24, order: 0 }}
          md={{ span: 24, order: 0 }}
          lg={{ span: 7, offset: 0, order: 1 }}
          xl={{ span: 6, offset: 0, order: 1 }}
          xxl={{ span: 4, offset: 2, order: 1 }}
        >
          <LoginComponent {...props} />
          <LanguageSelector />
          <Button
            onClick={() => props.navigate(`${ROUTES?.SETTINGS.path}`)}
            type="text"
          >
            <SettingOutlined rev="" />
          </Button>
        </Col>
      </Row>
    </Header>
  );
};
export default Navbar;
